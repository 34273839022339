.microsite footer {
  .footer-nav .level-two a {
      font-size: 0.875rem;
      line-height: 1.25rem;
      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
        line-height: 1.688rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
        line-height: 1.875rem;
      }
  }

  .contact-links a {
    font-size: 1rem;
    line-height: 1.5rem;
    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
      line-height: 1.688rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }
}