.people-cards {
  .people-card {
    max-width: 100%;

    .social-media-links {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(70px, auto));
      justify-content: start;
      row-gap: 32px;
      column-gap: 32px;
    }
  }

  p {
    margin-bottom: 0;
  }

  a {
    margin-bottom: 0;
  }

  .contact-phone-number a {
    text-decoration: none;
  }

  .contact-party + .contact-address,
  .contact-function + .contact-address,
  .contact-organisation + .contact-address,
  .contact-party + .contact-phone-number,
  .contact-function + .contact-phone-number,
  .contact-organisation + .contact-phone-number,
  .contact-address + .contact-phone-number,
  .contact-party + .contact-email,
  .contact-function + .contact-email,
  .contact-organisation + .contact-email,
  .contact-address + .contact-email {
    padding-top: 0.5rem;
  }

  .contact-name h3 {
    padding-right: 0.25rem;

    @include media-breakpoint-up(lg){
      padding-right: 0.5rem;
    }
  }

  .contact-function p{
    font-size: $zg_font-size-body-small;

    @include media-breakpoint-up(md) {
      font-size: $zg_font-size-body-medium;
    }

    @include media-breakpoint-up(lg) {
      font-size: $zg_font-size-body-large;
    }
  }

  address {
    margin-bottom: 0;
  }


  &.portrait-team {
    .people-card {
      background-color: $zg_color_bg_light;
    }

    img {
      max-width: 80px;
      height: fit-content;

      @include media-breakpoint-up(md) {
        max-width: 154px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 187px;
      }
    }
  }

  &.portrait-heads {
    .people-card:hover {
      background-color: $zg_color_bg_light;
    }

    img {
      max-width: 80px;
      height: fit-content;
      margin: auto 0;

      @include media-breakpoint-up(md) {
        max-width: 97px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 187px;
      }
    }

    .contact-organisation {
      padding-top: 0.5rem;

      a {
        text-decoration: underline;

      }
    }
  }
}

.people-cards.download-tile {
  border: none;

  &:hover {
    border: none;
  }
}

.contact-group .people-cards.portrait-team .people-card {
  height: 100%;
   .person-information {
    margin-top: 0 !important;
  }
}