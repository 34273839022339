.microsite {
  .py-36 {
    padding-top: 36px!important;
    padding-bottom: 36px!important;
  }

  header {
    display: flex;
    align-items: center;

    @media (min-width: 1025px) {
      .btn-header-nav.show::after {
          left: 50%;
          transform: translateX(-50%);
          bottom: -38px;
      }
    }

    nav a,
    nav button {
      flex: none;
      white-space: nowrap; /* Prevent text from wrapping */
      &.inactive {
        color: $zg_color_text_onLight_low_contrast;
      }
    }

    .main-nav-dropdown {
      margin-top: 0px;
      border-color: $zg_color_separator_onLight;
      button, a {
        white-space: initial;
      }
    }


    .logo {
        flex-grow: unset;
        margin-right: 2rem;
        img {
            height: 32px;
            object-fit: contain;
            object-position: left;
        }
    }

    #nav-mobile .btn-primary,
    .btn-primary.contact {
      color: $zg_color_text_onLight_high_contrast;
      border-width: 0;
      padding: 11px 48px 11px 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      font-size: 14px;
      margin-bottom: 0;
      border-radius: 5px;
      &::after {
        background-image: get-icon(arrowRight, $zg_color_text_onLight_high_contrast);
      }
    }

    .btn-icon.contact.arrowRight:hover:after {
        background-image: get-icon(arrowRight, $zg_color_text_onLight_high_contrast);
    }

    #nav-mobile {
      .list-wrapper.is-visible {
        scrollbar-width: none;
      }

      ul {
        &.level-1 {
          padding-bottom: 32px;
          li::after {
            display: none;

          }
        }

        &.level-2 {
          li {
            a::after {
              display: none;

            }
            .link-keywords {
              color: $zg_color_text_onLight_high_contrast;
              font-weight: 400;
            }
          }
        }

          li {
            border-top: none;
            margin-left: -16px;
            padding: 0 16px;
            width: 100vw;
            a {
              width: 100%;
              padding-left: 8px;
              border-bottom: 1px solid $zg_color_separator_onLight;
              color: #150023;

            }
            p {
              color: #150023;
            }
          }
          li:first-child {
            border-top: 1px solid $zg_color_separator_onLight;
          }
          li:last-child {
            border-bottom: none;
          }
        }
      }
    }
    
    .icon-wrapper {
      position: relative;
    }

    .icon-wrapper.icon-wrapper-small::after {
      width: 16px;
      height: 16px;
      margin: 2px auto 2px 10px;
      background-size: 12px;
    }

    .microsite-menu-dropdown {
    scrollbar-width: thin;

    .btn-close {
        position: absolute;
        top: 30px;
        right: 40px;
    }

    .link-intern {
      position: relative;
    }

    a {
      text-decoration: none;
      display: block;

      &:hover {
        outline: 1px solid $zg_color_border_default_onLight;
        background-color: $zg_color_bg_light;
      }
      &:active {
        outline: 3px solid $zg_color_focus;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        line-height: initial;
        font-size: 0.875rem;
        @include media-breakpoint-up(md) {
          font-size: 0.938rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: 1.125rem;
        }
      }
    }

    .link-section-two {
      padding-left: 0 !important;
      margin-left: -16px;

      li {
        flex-basis: 100%;
        @include media-breakpoint-up(lg) {
          flex-basis: 50%;
          max-width: 50%;
        }
      }
    }
  }
}
