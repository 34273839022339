html {
  //noinspection CssInvalidPropertyValue
  height: -webkit-fill-available; /* mobile viewport bug fix */
}

body {
  font-family: $zg_font-family;
  font-weight: $zg_font-weight-regular;
  font-size: $zg_font-size-body-small;
  line-height: $zg_line_height_big;
  color: $zg_color_text_onLight_medium_contrast;
  padding-top: 88px; //for fixed header on mobile
  overflow-wrap: break-word;
  position: relative;
  min-height: 100vh; /* mobile viewport bug fix */
  //noinspection CssInvalidPropertyValue
  min-height: -webkit-fill-available; /* mobile viewport bug fix */
  -webkit-font-smoothing: antialiased;

  @include media-breakpoint-up(md) {
    font-size: $zg_font-size-body-medium;
    padding-top: 0;
  }
  @include media-breakpoint-up(lg) {
    font-size: $zg_font-size-body-large;
  }

  *:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none !important;
  }

  *:focus-visible {
    outline: 3px solid $zg_color_focus !important;
    box-shadow: none;
  }
}

p, .p,
.microsite p,
.microsite .p {
  margin-bottom: 0rem;
  color: $zg_color_text_onLight_medium_contrast;

  &.bold {
    font-weight: $zg_font-weight-bold;
  }

  &.lead {
    font-size: $zg_font-size-lead-small;
    @include media-breakpoint-up(md) {
      font-size: $zg_font-size-lead-medium;
    }
    @include media-breakpoint-up(lg) {
      font-size: $zg_font-size-lead-large;
    }
  }

  &.small, 
  &.small p {
    font-size: $zg_font-size-small-small;
    line-height: 16px;
    @include media-breakpoint-up(md) {
      font-size: $zg_font-size-small-medium;
      line-height: 16.8px;

    }
    @include media-breakpoint-up(lg) {
      font-size: $zg_font-size-small-large;
      line-height: 20px;
    }
  }
}

.microsite p,
.microsite .p {
  font-size: $zg_ms_font-size-body-small;
  line-height: $zg_ms_line_height_small;
  @include media-breakpoint-up(md) {
    font-size: $zg_ms_font-size-body-medium;
    line-height: $zg_ms_line_height_medium;
  }
  @include media-breakpoint-up(lg) {
    font-size: $zg_ms_font-size-body-large;
    line-height: $zg_ms_line_height_large;
  }
}

a {
  color: $zg_color_text_onLight_hyperlink;
}

h1, .h1,
h2, .h2, .microsite .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.title {
  font-weight: $zg_font-weight-bold;
  line-height: $zg_line_height_medium;
  margin-bottom: 1rem;
  color: $zg_color_text_onLight_high_contrast;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  @include media-breakpoint-up(md) {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
  }
}

h1, .h1 {
  font-size: $zg_font-size-h1-small;
  @include media-breakpoint-up(md) {
    font-size: $zg_font-size-h1-medium;
  }
  @include media-breakpoint-up(lg) {
    font-size: $zg_font-size-h1-large;
  }

  &.title {
    font-size: $zg_font-size-title-small;
    @include media-breakpoint-up(md) {
      font-size: $zg_font-size-title-medium;
    }
    @include media-breakpoint-up(lg) {
      font-size: $zg_font-size-title-large;
    }
  }

}

h2, .h2, .microsite .h2{
  font-size: $zg_font-size-h2-small;
  @include media-breakpoint-up(md) {
    font-size: $zg_font-size-h2-medium;
  }
  @include media-breakpoint-up(lg) {
    font-size: $zg_font-size-h2-large;
  }
}

h3, .h3 {
  font-size: $zg_font-size-h3-small;
  @include media-breakpoint-up(md) {
    font-size: $zg_font-size-h3-medium;
  }
  @include media-breakpoint-up(lg) {
    font-size: $zg_font-size-h3-large;
  }
}

h4, .h4 {
  font-size: $zg_font-size-h4-small;
  @include media-breakpoint-up(md) {
    font-size: $zg_font-size-h4-medium;
  }
  @include media-breakpoint-up(lg) {
    font-size: $zg_font-size-h4-large;
  }
}

nav, .nav,
.nav-item {
  font-size: $zg_font-size-navigation;
  line-height: $zg_line_height_big;
}

label,
.form-control::placeholder {
  font-size: $zg_font-size-label;
}

input.form-control {
  border: 1px solid $zg_color_border_default_onLight;
}

hr {
  border-color: $zg_color_separator_onLight;
}

img[data-sizes="auto"] {
  display: block;
  width: 100%;
  height: auto;
}

ul, ol {
  li {
    line-height: 1.75rem;
  }
}

ul {
  list-style: disc outside none;
}

ol {
  list-style: decimal outside none;

  ol {
    list-style: lower-latin outside none;
  }
}

.spinner-border {
  border-color: $zg_color_text_onLight_high_contrast;
  border-right-color: transparent;
}

.mb-lg-8 {
  margin-bottom: 8px!important;
}

.microsite header .main-nav-dropdown .link-title::after,
.icon-wrapper::after {
  content: "";
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  background-size: 18px;
  margin: 3px auto 3px 10px;
  background-position: center;
  width: 24px;
  height: 24px;
}

// used in contact Box and people card
.social-media-links {
  a {
    text-decoration: none;
    color: $zg_color_text_onLight_medium_contrast;
    text-align: center;
    padding-top:4px;

    div {
      margin: auto;
    }

    span {
      font-size: 0.875rem;
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
  }

  .icon {
    &.social-icon {
      background-repeat: no-repeat;
      background-position: center;
      display: block;
      height: 56px !important;
      width: 56px !important;
      background-size: 70%;
      background-color: $zg_color_button_search;
      border-radius: 50%;
      margin-bottom: 0.5rem;
      &:hover,
      &:focus-visible,
      &:focus {
        background-color: $zg_color_button_hover;
      }
    }

    &.linkedin {
      background-image: get-icon-custom-width('linkedIn', 2.5, $zg_color_border_extraLight_onLight);
    }

    &.facebook {
      background-image: get-icon-custom-width('facebook', 2.5, $zg_color_border_extraLight_onLight);
    }

    &.instagram {
      background-image: get-icon-custom-width('instagram', 2.5, $zg_color_border_extraLight_onLight);
    }

    &.tiktok {
      background-image: get-icon-custom-width('tiktok', 2.5, $zg_color_border_extraLight_onLight);
    }

    &.twitter {
      background-image: get-icon-custom-width('twitter', 2.5, $zg_color_border_extraLight_onLight);
    }

    &.youtube {
      background-image: get-icon-custom-width('youtube', 2.5, $zg_color_border_extraLight_onLight);
    }
  }
}
