header .main-nav-dropdown .link-title.icon-wrapper::after,
.icon-wrapper::after {
  background-color: var(--zg_color_accent);
}

#nav-mobile .btn-primary,
.btn-primary.contact,
.microsite .heroArea .breadcrumb-navigation ol.breadcrumb li:before {
  background-color: var(--zg_color_accent);
}

@each $name, $theme in $zug-theme-colors {
  .#{$name} {
    $color: map-get($theme, 'accent');

    .btn-icon.arrowRight:after {
      background-image: get-icon('arrowRight', $color);
    }

    .btn-icon.diagonal:after {
      background-image: get-icon('diagonal', $color);
    }

    .btn-icon.link-download:after {
      background-image: get-icon('download', $color);
    }

    .quote-marks {
      content: get-icon('quote-marks', $color);
    }
  }
}

// Place the after styles after sass loop, otherwise sass loop rules overwrite this again

main .icon-wrapper-link {
  font-weight: $zg_font-weight-bold;
  color: $zg_color_primary !important;
  text-decoration: none;
}

.icon-wrapper.chevronRight::after {
  background-image: get-icon('chevronRight', $zg_color_text_onLight_high_contrast);
}

.icon-wrapper.arrowRight::after {
  background-image: get-icon('arrowRight', $zg_color_text_onLight_high_contrast);
}

.icon-wrapper.diagonal::after {
  background-image: get-icon('diagonal', $zg_color_text_onLight_high_contrast);
}

.icon-wrapper.link-download::after {
  background-image: get-icon('download', $zg_color_text_onLight_high_contrast);
}

.btn-icon.arrowRight:hover::after {
  background-image: get-icon('arrowRight', $zg_color_border_hover_onDark);
}

.btn-icon.diagonal:hover::after {
  background-image: get-icon('diagonal', $zg_color_border_hover_onDark);
}

.btn-icon.link-download:hover::after {
  background-image: get-icon('download', $zg_color_border_hover_onDark);
}

// different styles for zg-ch icons (load last to overwrite everything at the top)
.zg-ch {
  .icon-wrapper::after {
    background-color: transparent;
  }

  .icon-wrapper.arrowRight:after {
    background-image: get-icon('arrowRight', $zg_color_accent);
  }

  .icon-wrapper.diagonal:after {
    background-image: get-icon('diagonal',  $zg_color_accent);
  }

  .icon-wrapper.link-download:after {
    background-image: get-icon('download',  $zg_color_accent);
  }
}

