$zg_font_size_button_large: 1.125rem;
$zg_font_size_button_medium: 0.9375rem;
$zg_font_size_button_small: 0.875rem;

@mixin icon-getter-after($default-color, $hover-color) {
  @each $name, $icon in $icon-map {
    &.#{$name} {
      &:hover:after,
      &:focus:after,
      &:active:after {
        background-image: get-icon($name, $hover-color);
      }

      &::after {
        background-image: get-icon($name, $default-color);
      }
    }
  }
}

@mixin icon-getter-after-custom-width($width, $default-color, $hover-color) {
  @each $name, $icon in $icon-map {
    &.#{$name} {
      &:hover:after,
      &:focus:after,
      &:active:after {
        background-image: get-icon-custom-width($name, $width, $hover-color);
      }

      &::after {
        background-image: get-icon-custom-width($name, $width, $default-color);
      }
    }
  }
}

@mixin icon-getter-center($default-color, $hover-color) {
  @each $name, $icon in $icon-map {
    &.#{$name} {
      background-image: get-icon($name, $default-color);

      &:hover,
      &:focus,
      &:active {
        background-image: get-icon($name, $hover-color);
      }
    }
  }
}

@mixin icon-getter-center-focus-is-default($default-color, $hover-color) {
  @each $name, $icon in $icon-map {
    &.#{$name} {
      background-image: get-icon($name, $default-color);

      &:focus,
      &:active {
        background-image: get-icon($name, $default-color);
      }

      &:hover {
        background-image: get-icon($name, $hover-color);
      }
    }
  }
}

.btn {
  box-sizing: border-box;
  padding: 0.6875rem 2rem;
  line-height: 1.375rem;
  border: 1px solid $zg_color_border_default_onLight;
  font-size: $zg_font_size_button_small;
  display: inline-block;
  position: relative;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  @include media-breakpoint-up(md){
    font-size: $zg_font_size_button_medium;
    padding: 0.75rem 3rem;
    line-height: 1.5rem;
  }
  @include media-breakpoint-up(lg){
    font-size: $zg_font_size_button_large;
    padding: 0.9375rem 3rem;
    border-radius: 10px;
    line-height: 1.75rem;
  }

  &:hover {
    color: $zg_color_text_onDark_hyperlink;
    background-color: $zg_color_button_hover;
    border: 1px solid  $zg_color_button_hover;
  }

  &:focus {
    border: none;
    box-shadow: none;
  }

  &.btn-secondary {
    background-color: transparent;
    color: $zg_color_text_onLight_hyperlink;
    &:hover {
      color: $zg_color_text_onDark_hyperlink;
      background-color: $zg_color_button_hover;
      border: 1px solid  $zg_color_button_hover;
    }
  }

  &.btn-blue {
    background-color: $zg_color_button_search;
    color: $zg_color_text_onDark_hyperlink;
    border: none;
    &:hover {
      color: $zg_color_text_onDark_hyperlink;
      background-color: $zg_color_button_hover;
      border: none;
    }
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &.btn-disabled,
  &.btn-disabled:hover,
  &.btn-disabled:focus {
    background-color:transparent;
    color: $zg_color_text_onLight_low_contrast;
    border: 1px solid $zg_color_border_disabled_onLight;
    opacity: 1;
  }
}

.btn-icon {
  padding: 0.6875rem 2.75rem 0.6875rem 0.6875rem;
  &::after {
    background: center / 1rem 1rem no-repeat;
    @include media-breakpoint-up(md){
      background: center / 1.4rem 1.4rem no-repeat;
    }
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 3rem;
    line-height: 1.563rem;
  }
  @include icon-getter-after($zg_color_accent, $zg_color_text_onDark_hyperlink);
  &.btn-blue {
    @include icon-getter-after($zg_color_text_onDark_hyperlink, $zg_color_text_onDark_hyperlink);

    &.search {
      @include icon-getter-after-custom-width(7, $zg_color_text_onDark_hyperlink, $zg_color_text_onDark_hyperlink)
    }
  }

  &.btn-text-icon {
    @include icon-getter-after($zg_color_text_onLight_high_contrast, $zg_color_text_onLight_high_contrast);
  }

  &:disabled,
  &.btn-disabled {
    @include icon-getter-after($zg_color_text_onLight_low_contrast, $zg_color_text_onLight_low_contrast);
  }

  @include media-breakpoint-up(md){
    padding: 0.75rem 3rem 0.75rem 1rem;
  }
  @include media-breakpoint-up(lg){
    padding: 0.9375rem 3.5rem 0.9375rem 1rem;
  }
}

.btn-icon-only {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  background-position: center;
  background-size: 1.5rem;
  background-repeat: no-repeat;

  &.text-color {
    @include icon-getter-center($zg_color_text_onLight_high_contrast, $zg_color_text_onLight_high_contrast);
  }

  &.accent-on-hover {
    @include icon-getter-center($zg_color_text_onLight_low_contrast, $zg_color_accent);
  }

  &.open-modal {
    @include icon-getter-center($zg_color_text_onLight_high_contrast, $white);
  }

  &.gallery-navigation {
    @include icon-getter-center-focus-is-default($zg_color_accent, $white);
  }

  &.btn-disabled {
    @include icon-getter-center($zg_color_text_onLight_low_contrast, $zg_color_text_onLight_low_contrast);
  }
}

.btn-icon-in-circle {
  padding: 0.75rem 3rem 0.75rem 1rem;

  &:after {
    width: 1.5rem;
    height: 1.5rem;
    background-repeat: no-repeat;
    background-position: center;
    content: "";
    position: absolute;
    top: 12px;
    background-size: 1rem;
    background-color: var(--zg_color_accent);
    border-radius: 50%;
    right: 12px;
    box-sizing: border-box;
  }

  @include media-breakpoint-up(lg){
    padding: 0.75rem 4rem 0.75rem 1rem;
    &:after {
      width: 2rem;
      height: 2rem;
      background-size: 1.4rem;
      top: 10px;
      right: 16px;
    }
  }

  @include icon-getter-after($black, $black);

}

@each $name, $theme in $zug-theme-colors {
  .#{$name} {
    .btn-icon-only {
      &.gallery-navigation,
      &.pagination-button {

        &.arrowRight:not(.btn-disabled):not(:hover):not(:disabled) {
          background-image: get-icon(arrowRight, map-get($theme, 'accent'));
        }
        &.arrowLeft:not(.btn-disabled):not(:hover):not(:disabled) {
          background-image: get-icon(arrowLeft, map-get($theme, 'accent'));
        }
      }
    }
  }
}
