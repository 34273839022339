.news-detail {
  .release-info {
    font-size: 0.875rem;
    line-height: 20px;
    color: $zg_color_text_onLight_medium_contrast;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }
  }
  .image-block {
     img {
       padding-bottom: 16px;
     }
  }

  .video-container {
    width: 100%;
    iframe {
      width: 100%;
      aspect-ratio: 16 / 9;
      height: auto;
    }

    video {
      width: 100%;
    }
  }

  .image-block,
  .video-container {
    .image-caption,
    .video-caption {
      display: block;
    }

    .image-credit,
    .video-credit {
      font-size: $zg_font-size-small-small;
      @include media-breakpoint-up(md) {
        font-size: $zg_font-size-small-medium;
      }
      @include media-breakpoint-up(lg) {
        font-size: $zg_font-size-small-large;
      }
    }
  }

  .link-list {
    padding: 0 !important;

    + div:not(.link-list){
      margin-top: 1rem;
    }

    + .link-list {
        .list-link {
          border-top: none;
        }
      }
  }

  .contact-news.people-cards {
    p {
      line-height: 1.375rem;
      @include media-breakpoint-up(md) {
        line-height:1.5rem;
      }
      @include media-breakpoint-up(lg) {
        line-height: 1.75rem;
      }
    }

    a {
      display: block;
      line-height: 1.75rem;
      font-size: 1.125rem;

      &:first-of-type {
        padding-top: 0.5rem;
        @include media-breakpoint-up(lg) {
          padding-top: 0.75rem;
        }
      }
    }
  }
}