// MAIN COLORS
$zg_color_primary: #252342;
$zg_color_secondary: #0070B8;
$zg_color_accent: #E67535;
$zg_color_error: #E53E3E;
$zg_color_focus: #7CA5D0;

// SURFACES
$zg_color_bg_white: #FFFFFF;
$zg_color_bg_light: #F5F9FF;
$zg_color_bg_dark: $zg_color_primary;

// TEXT & GLYPHS
// Light Mode
$zg_color_text_onLight_high_contrast: #150023;
$zg_color_text_onLight_medium_contrast: #344060;
$zg_color_text_onLight_low_contrast: #687198;
$zg_color_text_onLight_hyperlink: #2D4487;
// Dark Mode
$zg_color_text_onDark_high_contrast: #E0EEF6;
$zg_color_text_onDark_medium_contrast: #A3B5C1;
$zg_color_text_onDark_low_contrast: #848DB4;
$zg_color_text_onDark_hyperlink: #F5F9FF;

// UI ELEMENTS
// Buttons (no dark or light mode)
$zg_color_button_primary: $zg_color_primary;
$zg_color_button_secondary: none; // Transparent
$zg_color_button_tertiary_light: $zg_color_bg_light;
$zg_color_button_tertiary_dark: #2E335C;
$zg_color_button_search: #3F74B0;
$zg_color_button_hover: #005D99;
// Border colors
$zg_color_border_error: $zg_color_error;
// Border colors - Light Mode
$zg_color_separator_onLight: #D5D7E6;
$zg_color_border_default_onLight: #ABB3CC;
$zg_color_border_hover_onLight: #2D4487;
$zg_color_border_disabled_onLight: #EAEBF0;
$zg_color_border_extraLight_onLight: #EAEBF0;
// Border colors - Dark Mode
$zg_color_separator_onDark: #435075;
$zg_color_border_default_onDark: #848DB4;
$zg_color_border_hover_onDark: #F5F9FF;
$zg_color_border_disabled_onDark: #525B60;

// Overwrite Bootstrap Default Colors
$primary: $zg_color_primary;
$secondary: $zg_color_secondary;
$success: $zg_color_secondary;
$info: $zg_color_accent;
$warning: $zg_color_error;
$danger: $zg_color_error;
$light: $zg_color_bg_light;
$dark: $zg_color_bg_dark;

// Accordion
$accordion-icon-color: $zg_color_accent;
$accordion-icon-active-color: $zg_color_accent;
$accordion-button-active-bg: $zg_color_bg_white;
$accordion-border-color: $zg_color_separator_onLight;

// Dropdown
$dropdown-border-color: $zg_color_border_default_onLight;
$dropdown-link-color: $zg_color_text_onLight_medium_contrast;
$dropdown-link-hover-color: $zg_color_text_onLight_medium_contrast;
$dropdown-link-hover-bg: transparent;

//Select
$form-select-indicator-color: $zg_color_accent;

// ACCENT COLORS (THEME)
$zg_color_accent_yellow: #FCE400;
$zg_color_accent_orange: #DB8F1B;
$zg_color_accent_red: #C20D20;
$zg_color_accent_magenta: #C3007A;
$zg_color_accent_purple: #634992;
$zg_color_accent_cyan: #4BAEE7;
$zg_color_accent_wood: #22945B;
$zg_color_accent_grass: #75B837;
$zg_color_accent_olive: #A0A356;
$zg_color_accent_yellowgreen: #D2D742;


// Set Accent color based on Theme
:root {
  --zg_color_accent: #{$zg_color_accent};
  --zg_color_accent_transparent: #{rgba($zg_color_accent, 0.5)};
}

.zg-yellow {
  --zg_color_accent: #{$zg_color_accent_yellow};
  --zg_color_accent_transparent: #{rgba($zg_color_accent_yellow, 0.5)};
}

.zg-orange {
  --zg_color_accent: #{$zg_color_accent_orange};
  --zg_color_accent_transparent: #{rgba($zg_color_accent_orange, 0.5)};
}

.zg-red {
  --zg_color_accent: #{$zg_color_accent_red};
  --zg_color_accent_transparent: #{rgba($zg_color_accent_red, 0.5)};
}

.zg-magenta {
  --zg_color_accent: #{$zg_color_accent_magenta};
  --zg_color_accent_transparent: #{rgba($zg_color_accent_magenta, 0.5)};
}

.zg-purple {
  --zg_color_accent: #{$zg_color_accent_purple};
  --zg_color_accent_transparent: #{rgba($zg_color_accent_purple, 0.5)};
}

.zg-cyan {
  --zg_color_accent: #{$zg_color_accent_cyan};
  --zg_color_accent_transparent: #{rgba($zg_color_accent_cyan, 0.5)};
}

.zg-wood {
  --zg_color_accent: #{$zg_color_accent_wood};
  --zg_color_accent_transparent: #{rgba($zg_color_accent_wood, 0.5)};
}

.zg-grass {
  --zg_color_accent: #{$zg_color_accent_grass};
  --zg_color_accent_transparent: #{rgba($zg_color_accent_grass, 0.5)};
}

.zg-olive {
  --zg_color_accent: #{$zg_color_accent_olive};
  --zg_color_accent_transparent: #{rgba($zg_color_accent_olive, 0.5)};
}

.zg-yellowgreen {
  --zg_color_accent: #{$zg_color_accent_yellowgreen};
  --zg_color_accent_transparent: #{rgba($zg_color_accent_yellowgreen, 0.5)};
}

// Theme colors map (to use colors in sass)
$zug-theme-colors: (
        'zg-ch': (
                'accent': $zg_color_accent,
        ),
        'zg-yellow': (
                'accent': $zg_color_accent_yellow,
        ),
        'zg-orange': (
                'accent': $zg_color_accent_orange,
        ),
        'zg-red': (
                'accent': $zg_color_accent_red,
        ),
        'zg-magenta': (
                'accent': $zg_color_accent_magenta,
        ),
        'zg-purple': (
                'accent': $zg_color_accent_purple,
        ),
        'zg-cyan': (
                'accent': $zg_color_accent_cyan,
        ),
        'zg-wood': (
                'accent': $zg_color_accent_wood,
        ),
        'zg-grass': (
                'accent': $zg_color_accent_grass,
        ),
        'zg-olive': (
                'accent': $zg_color_accent_olive,
        ),
        'zg-yellowgreen': (
                'accent': $zg_color_accent_yellowgreen,
        ),
);