.dropdown {
  .btn-language-switcher,
  .btn-language-switcher:hover {
    margin-left: 0;
    margin-right: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    color: $zg_color_text_onLight_high_contrast;
    font-weight: $zg_font-weight-regular;
    font-size: $zg_font-size-header-nav_small;
    min-width: 75px;

    @include media-breakpoint-up(md) {
      padding: 0.813rem 2.25rem 0.813rem 1rem;

      &::after {
        background: center / 1rem 1rem no-repeat;
      }
    }

    @include media-breakpoint-up(lg) {
      font-size: $zg_font-size-header-nav;
      margin-left: $zg_menu-items-header-margin;
      margin-right: $zg_menu-items-header-margin;
      padding: 1rem 2.25rem 1rem 1rem;
    }

    &::after {
      background-image: get-icon('chevronDown', $zg_color_text_onLight_high_contrast);
    }

    &.show:after {
      background-image: get-icon('chevronUp', $zg_color_accent);
    }

  }

  p.btn-language-switcher::after,
  p.btn-language-switcher:hover::after{
    background-image: none;
  }

  .dropdown-menu {
    min-width: 1rem;
    width: 124px;
    border-radius: $zg_border-radius-middle;

    .dropdown-item {
      padding-left: 1.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      &:hover {
        font-weight: $zg_font-weight-bold;
        text-decoration: none;
      }
    }
  }
}

.languageSwitcherMobile {
  border: none;
  color: $zg_color_text_onLight_high_contrast;
  font-weight: $zg_font-weight-regular;
  font-size: $zg_font-size-header-nav_small;
  min-width: 80px;
  background-size: 1.2rem;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #ffffff;

  &:hover {
    cursor: pointer;
  }
}


.microsite {

  header .dropdown .btn-language-switcher::after {
    top: 20px;
    left: 47px;
  }

  footer .dropdown .btn-language-switcher::after {
    top: 4px;
    left: 32px;
  }



  .dropdown {
    button.btn-language-switcher:after,
    button.btn-language-switcher:hover:after {
      background-image: get-icon-custom-width(chevronDown, 7, $zg_color_text_onLight_high_contrast);
      background-color: var(--zg_color_accent);
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
    }
    button.btn-language-switcher.show:after,
    button.btn-language-switcher:hover.show:after {
      background-image: get-icon-custom-width(chevronUp, 7, $zg_color_text_onLight_high_contrast);
    }
  }

  footer {
    .dropdown {
      .btn-language-switcher.show:after,
      .btn-language-switcher:hover.show:after {
        top: 1px;
        left: 30px;
      }
      .dropdown-menu {
        transform: translate3d(-47px, -45px, 0px) !important;
      }
    }
  }
}
