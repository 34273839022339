.microsite {
    .select-wrapper.icon-wrapper {
        position: static;
        &::after {
            background-image: get-icon('chevronDown', $zg_color_text_onLight_high_contrast);
            height: 24px;
            position: absolute;
            right: 16px;
            top: 29px;
            pointer-events: none;
            @include media-breakpoint-up(md){
                top: 32px;
            }
            @include media-breakpoint-up(lg){
                top: 36.2px;
            }
        }        
        select {
            background-image: none;
        }
    }

    .date-input.icon-wrapper {
        &::-webkit-inner-spin-button,
        &::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }
        &::after {
            background-image: get-icon('calendar', $zg_color_text_onLight_high_contrast);
            background-size: 14px;
            position: absolute;
            right: 16px;
            top: 13px;
            @include media-breakpoint-up(md){
                top: 17px;
            }
            @include media-breakpoint-up(lg){
                top: 26px;
            }
        }
    }

    .btn-wrapper.icon-wrapper  {
        input.btn-step-back {
            background-image: none;
        }
        &:after {
            background-image: get-icon(arrowLeft, $zg_color_text_onLight_high_contrast);
            height: 16px;
            width: 16px;
            background-size: 12px;
            position: absolute;
            right: 16px;
            top: 30px;
            @include media-breakpoint-up(md){
                background-image: get-icon(arrowLeft, $zg_color_text_onLight_high_contrast);
                height: 24px;
                width: 24px;
                background-size: 16px;
                top: 27px;
            }
            @include media-breakpoint-up(lg){
                top: 32px;
            }
        }
    }
}

@each $name, $theme in $zug-theme-colors {
    .#{$name} {
        $color: map-get($theme, 'accent');

        .form-wrapper fieldset .button-wrapper input {
            &.btn-submit {
                background-image: get-icon('arrowRight',  $color);
            }  
        }
    }
}