.breadcrumb-navigation {
  ol.breadcrumb {
    width: fit-content;

    li {
      color: $zg_color_text_onLight_medium_contrast;

      &.dark {
        color: $zg_color_text_onDark_medium_contrast;
      }
      
      line-height: 20px;
      padding-left: 0;

      &:before {
        content: url("data:image/svg+xml,%3Csvg width='10' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 9 6 L 5 2 L 1 6' stroke='%23E67535' stroke-width='2' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E") !important;
        padding-right: 0.5rem;
      }

      &:first-child:before {
        padding-right: 0.3rem;
        @include media-breakpoint-up(md) {
          padding-right: 0;
        }
      }

      &.no-icon{
        &:before{
          content: '' !important;
          padding-right: 0;
        }
      }

      a {
        color: $zg_color_text_onLight_medium_contrast;
        text-decoration: none;

        &:hover{
          text-decoration: underline;
          color: $zg_color_text_onLight_high_contrast;
        }
      }

        &.dark a {
        color: $zg_color_text_onDark_medium_contrast;
        text-decoration: none;

        &:hover{
          text-decoration: underline;
          color: $zg_color_text_onDark_high_contrast;
        }
      }
      
      @include media-breakpoint-up(md) {

        &:before {
          content: url("data:image/svg+xml,%3Csvg width='6' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1.313 8.188 3.375-3.376-3.375-3.375' stroke='%23848DB4' stroke-miterlimit='10' stroke-linecap='square'/%3E%3C/svg%3E") !important;
          color: $zg_color_text_onLight_low_contrast !important;
          padding-left: 0.5rem;
        }

        &:first-child:before {
          content: '' !important;
          padding-left: 0;
        }
      }

      @include media-breakpoint-up(lg){
        font-size: $zg_font-size-h4-small;
        line-height: 26px;
      }
    }
  }
}

// For templates that do not use class .title on h1 in hero
.news-detail, .statement-detail {
  .breadcrumb-navigation ol.breadcrumb li:first-child:before {
    padding-right: 0.15rem;
  }
}