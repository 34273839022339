// Font Family
$zg_font-family: 'Inter', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

// Font sizes
$zg_font-size-body-large: 1.125rem;
$zg_font-size-body-medium: 0.9375rem;
$zg_font-size-body-small: 0.875rem;

$zg_font-size-small-large: 0.875rem;
$zg_font-size-small-medium: 0.75rem;
$zg_font-size-small-small: 0.6875rem;

$zg_font-size-title-large: 4.5rem;
$zg_font-size-title-medium: 3.375rem;
$zg_font-size-title-small: 2.125rem;

$zg_font-size-h1-large: 3.5rem;
$zg_font-size-h1-medium: 2.25rem;
$zg_font-size-h1-small: 1.875rem;

$zg_font-size-h2-large: 2.25rem;
$zg_font-size-h2-medium: 1.75rem;
$zg_font-size-h2-small: 1.5rem;

$zg_font-size-h3-large: 1.75rem;
$zg_font-size-h3-medium: 1.25rem;
$zg_font-size-h3-small: 1.125rem;

$zg_font-size-h4-large: 1.25rem;
$zg_font-size-h4-medium: 1rem;
$zg_font-size-h4-small: 1rem;

$zg_font-size-lead-large: 1.625rem;
$zg_font-size-lead-medium: 1.25rem;
$zg_font-size-lead-small: 1rem;

$zg_font-size-navigation: 0.875rem;

$zg_font-size-label: 1rem;
$zg_font-size-label-medium: 0.813rem;
$zg_font-size-label-small: 0.75rem;

// Line Heights
$zg_line_height_auto: auto;
$zg_line_height_medium: 1.2;
$zg_line_height_big: 1.4;

// Font-weight
$zg_font-weight-regular: 400;
$zg_font-weight-bold: 700;

// microsites sizes
$zg_ms_font-size-body-large: 1.25rem;
$zg_ms_font-size-body-medium: 1.125rem;
$zg_ms_font-size-body-small: 1rem;

$zg_ms_line_height_large: 1.875rem;
$zg_ms_line_height_medium: 1.6875rem;
$zg_ms_line_height_small: 1.5rem;