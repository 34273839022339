#cookie-banner {
  box-shadow: 0 -4px 10px rgba(130, 130, 130, 0.12);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  color: $zg_color_text_onLight_high_contrast;
  font-weight: $zg_font_weight-bold;
  z-index: 100000;
  padding: 2rem;
  border-top: 1px solid $zg_color_text_onLight_high_contrast;

  .container {
    flex-direction: column;
    max-width: 100%;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &:not(.accepted) {
    transform: translateY(100%);
  }

  .cookie-banner-links {
    flex-grow: 1;
    display: flex;
  }

  &.pending {
    animation: slideIn 0.4s ease-in-out 0.6s;
    animation-fill-mode: forwards;
  }

  &.accepted {
    animation: slideOut 0.4s ease-in-out 0.1s;
    animation-fill-mode: forwards;
  }

  .container {
    gap: 1rem;
  }

  a {
    color: $zg_color_text_onLight_high_contrast;
    text-underline-offset: 0.3rem;
  }

  a.cookie-banner_accept-link {
    margin-left: auto;
    text-decoration-thickness: 0.2rem;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

