.title-text-link {

  h2 + div {
    p {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      &:has(~ h3),
      &:has(~ h4) {
        margin-bottom: 0;
      }
    }

    ul:last-child,
    li:last-child {
      margin-bottom: 0;
    }
  }


  .two-column {
    column-count: 2;
    column-gap: 8px;
    @include media-breakpoint-up(md) {
      column-gap: 24px;
    }

    @include media-breakpoint-up(lg) {
      column-gap: 32px;
    }

    h3, h4 {
      column-span: all;
    }
  }

  h3:not(:first-child),
  h4:not(:first-child) {
    margin-top: 32px;
  }

  .block-link-wrapper {
    background-color: $zg_color_bg_light;
    border-radius: $zg_border-radius-middle;

    &:hover {
      outline: 1px solid $zg_color_border_hover_onLight;
    }

    &:focus-within{
      outline: 3px solid $zg_color_focus;
    }

    .block-link {
      display: block;
      text-decoration: none;
      padding: 16px;
      @include media-breakpoint-up(md) {
        padding: 26px 16px;
      }

      @include media-breakpoint-up(lg) {
        padding: 32px;
      }

      &:focus-visible {
        outline: none !important;
      }

      .card-title {
        font-weight: $zg_font-weight-bold;
        color: $zg_color_text_onLight_high_contrast;
        margin-bottom: 0;
        font-size: 1rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.25rem;
        }

        span {
          display: inline-block;
          width: 85%;
        }

        &:after {
          content: '';
          background-size: 1.5rem;
          background-repeat: no-repeat;
          background-position: center -2px;
          display: inline-block;
          height: 1.5rem;
          width: 2.6rem;
          position: relative;
          top: 2px;
          float: right;
        }

        &.link-intern {
          &:after {
            background-image: get-icon('arrowRight', $zg_color_accent);
          }
        }

        &.link-extern {
          &:after {
            background-image: get-icon('diagonal', $zg_color_accent);
          }
        }

        &.link-download {
          &:after {
            background-image: get-icon('download', $zg_color_accent);
          }
        }

        &.bg-color {
          &:after {
            background-color: var(--zg_color_accent);
            border-radius: 50px;
            height: 1.5rem;
            width: 1.5rem;
            background-position: center;
            background-size: 1rem;
            top: 0;
            padding: 4px;
            @include media-breakpoint-up(lg) {
              height: 2rem;
              width: 2rem;
              background-size: 1.5rem;
            }
          }

          &.link-intern {
            &:after {
              background-image: get-icon('arrowRight', $zg_color_text_onLight_high_contrast);
            }
          }

          &.link-extern {
            &:after {
              background-image: get-icon('diagonal', $zg_color_text_onLight_high_contrast);
            }
          }

          &.link-download {
            &:after {
              background-image: get-icon('download', $zg_color_text_onLight_high_contrast);
            }
          }
        }
      }
    }
  }
}