.suggestions-list {
  list-style: none;
  padding-top: 0.5rem;
  padding-left: 0;
  margin-bottom: 0;

  li {
    border-bottom: 1px solid $zg_color_separator_onLight;

    &:last-child {
      border: none !important;
    }

    a {
      color: $zg_color_text_onLight_high_contrast;
      text-decoration: none;
      font-size: $zg_font-size-body-small;
      padding-left: 1rem;

      @include media-breakpoint-up(md) {
        font-size: $zg_font-size-body-medium;
      }

      @include media-breakpoint-up(lg) {
        font-size: $zg_font-size-body-large;
      }
    }
  }
}

.col-suggestions-list {
  top: -80px;
  @include media-breakpoint-up(md) {
    top: 0;
  }

  .suggestions-list {
    padding-top: 0;
    @include media-breakpoint-up(md) {
      top: 0.5rem;
    }
  }

}