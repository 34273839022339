$zg_font-size-header-nav: 1rem;
$zg_font-size-header-nav_small: 0.875rem;
$zg_line-height-header-nav: 1.625rem;
$zg_line-height-header-nav_small: 1.25rem;
$zg_menu-items-header-margin: 1rem;
$zg_menu-items-header-margin_small: 0.75rem;
$zg_header-height-mobile: 80px;
$zg_header-height-desktop: 97px;

header {
  background-color: $zg_color_bg_white;
  height: $zg_header-height-mobile;
  position: fixed;
  top: 0;
  transition: 0.4s ease all;
  width: 100%;
  z-index: 20;
  border-bottom: 1px solid transparent;
  @include media-breakpoint-up(md) {
    position: relative;
  }
  @include media-breakpoint-up(lg) {
    height: $zg_header-height-desktop;
  }

  &.border-bottom {
    border-bottom: 1px solid $zg_color_border_default_onLight !important;
  }

  &.header-up { // Add this function with js to hide it while scrolling down
    top: -$zg_header-height-mobile;
  }

  .logo img {
    aspect-ratio: 148 / 32;
    width: 110px;
    @include media-breakpoint-up(md) {
      width: 150px;
    }
  }

  .btn.btn-header-nav,
  .icon-header-menu {
    margin-left: $zg_menu-items-header-margin_small;
    margin-right: $zg_menu-items-header-margin_small;
    @include media-breakpoint-up(lg) {
      margin-left: $zg_menu-items-header-margin;
      margin-right: $zg_menu-items-header-margin;
    }
  }

  .btn.btn-header-nav,
  .btn.btn-header-nav:hover {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
    color: $zg_color_text_onLight_high_contrast;
    font-weight: $zg_font-weight-regular;
    font-size: $zg_font-size-header-nav_small;
    line-height: $zg_line-height-header-nav_small;
    @include media-breakpoint-up(lg) {
      font-size: $zg_font-size-header-nav;
      line-height: $zg_line-height-header-nav;
    }
  }

  .btn.btn-header-nav.btn-icon {
    padding: 0 1.5rem 0 0;
    @include media-breakpoint-up(md) {
      padding: 0 2rem 0 0;
    }
  }

  .btn-header-nav.btn-icon.btn-text-icon {
    margin-right: 0;

    &:after {
      width: 1.4rem
    }
  }

  .btn-header-nav.btn-icon-only,
  .btn-header-nav.btn-icon-only:hover {
    border-left: 1px solid $zg_color_separator_onLight;
    border-right: 1px solid $zg_color_separator_onLight;
    padding-left: $zg_menu-items-header-margin_small*2;
    padding-right: $zg_menu-items-header-margin_small*2;
    @include media-breakpoint-up(lg) {
      margin-left: $zg_menu-items-header-margin;
      margin-right: $zg_menu-items-header-margin;
      padding-left: $zg_menu-items-header-margin*2;
      padding-right: $zg_menu-items-header-margin*2;
    }
  }

  .btn-header-nav {
    &.show {
      & ~ button {
        color: $zg_color_text_onLight_low_contrast;
      }

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 13px solid $zg_color_border_default_onLight;
        display: block;
        position: absolute;
        bottom: -27px;
        @include media-breakpoint-up(lg) {
          bottom: -30px;
        }
      }
    }

    &.not-active {
      color: $zg_color_text_onLight_low_contrast;
    }
  }

  #organisationsMenuButton.show:after {
    left: 34px;
  }

  #themesMenuButton.show:after {
    left: 18px;
  }

  .icon-header-menu {
    background-size: 1.5rem;
    background-repeat: no-repeat;
    background-position: center;
    height: 1.5rem;
    width: 1.5rem;
    display: inline-block;

    &.person {
      background-image: get-icon('person', $zg_color_text_onLight_high_contrast);
    }
  }

  .btn-icon.hamburgersearch {
    line-height: 2rem;
    margin-right: 0;
    padding: 0 2rem 0 0 !important;

    &:after {
      background: center / 1.4rem 1.4rem no-repeat;
      top: 2px;
      width: 1.4rem;
    }

    &:hover {
      margin-right: 0;
    }
  }

  // All dropdowns in Nav
  .link-title, .link-keywords {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    .link-title {
      font-size: 1.125rem;
      line-height: 1.75rem
    }
    .link-keywords {
      font-size: 1rem;
      line-height: 1.625rem;
    }
  }

  .main-nav-dropdown {
    border-color: $zg_color_border_default_onLight;
    border-width: 1px;
    border-style: solid;
    overflow: auto;

    @media screen and (min-width: 1600px) {
      max-width: 1590px;
      margin-left: calc((100vw - 1590px) / 2) !important;
    }


    .link-title {
      font-weight: $zg_font-weight-bold;
      color: $zg_color_text_onLight_high_contrast;
      width: 90%;
      white-space: break-spaces;
      word-break: break-word;

      &::after {
        content: '';
        background-size: 1.5rem;
        background-repeat: no-repeat;
        background-position: right;
        display: inline-block;
        height: 1.875rem;
        width: 2.5rem;
        position: absolute;
        vertical-align: middle;
      }

      &.link-extern::after {
        background-image: get-icon('diagonal', $zg_color_accent);
      }

      &.link-intern::after {
        background-image: get-icon('chevronRight', $zg_color_accent);
      }
    }
  }

  .themes-menu-dropdown {
    padding: 0 0 32px 0;
    margin: 0;

    > .container {
      height: 100%;
    }

    .top-line {
      min-height: 80px;

      .btn-close-main-nav-themes {
        z-index: 20;
        position: relative;
        @include media-breakpoint-up(lg) {
          right: -16px;
        }
      }
    }

    .btn-group.dropend {
      width: 100%;

      .btn-themes-menu-theme {
        width: 100%;
        border: none;
        border-radius: 0;
        color: $zg_color_text_onLight_medium_contrast;
        font-weight: $zg_font-weight-regular;
        text-align: left;
        padding: 16px 48px 16px 16px;
        margin-bottom: 0;
        white-space: normal;

        &:hover, &:active, &[aria-expanded="true"] {
          color: $zg_color_text_onLight_high_contrast;
          font-weight: $zg_font_weight-bold;
        }

        &:hover {
          background-color: transparent;
        }

        &:active, &[aria-expanded="true"] {
          background-color: $zg_color_bg_light;
        }

        &:focus-visible {
          outline: none;
          border: 1px solid $zg_color_focus;
          z-index: 20;
        }

        &::after {
          all: unset;
          content: '';
          background-size: 1.5rem;
          background-repeat: no-repeat;
          background-position: right;
          display: inline-block;
          height: 1.5rem;
          width: 100%;
          background-image: get-icon('chevronRight', $zg_color_accent);
          position: absolute;
          top: 17px;
          right: 9px;
        }
      }

      .dropdown-menu {
        width: 1000px;
        background-color: $zg_color_bg_light;
        border-radius: 0;
        border: none;
        border-radius: 0 10px 10px 0;
        padding-left: 48px;
        padding-top: 80px;
        @include media-breakpoint-up(lg) {
          padding-left: 128px;
        }

        &.show {
          display: flex;
          flex-wrap: wrap;
          align-content: start;
          overflow-y: auto;
          overflow-x: hidden;
          z-index: 10;
          scrollbar-width: thin;
        }

        li {
          font-size: 0.875rem;
          flex-basis: 100%;
          @include media-breakpoint-up(md) {
            font-size: 0.938rem;
          }
          @include media-breakpoint-up(lg) {
            font-size: 1.125rem;
            flex-basis: 50%;
          }

          a {
            text-decoration: none;
            display: inline-block;
            width: 100%;

            .link-title {
              width: 85%;
            }

            &:focus {
              border-radius: 0 !important;
            }

            &:hover {
              outline: 1px solid $zg_color_border_default_onLight;
            }
          }
        }
      }
    }
  }

  .organisations-menu-dropdown {
    scrollbar-width: thin;

    p:first-of-type {
      font-size: 0.875rem;
      @include media-breakpoint-up(md) {
        font-size: 0.938rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        font-size: 0.875rem;
        @include media-breakpoint-up(md) {
          font-size: 0.938rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: 1.125rem;
        }

        a {
          text-decoration: none;
          display: block;

          &:focus {
            border-radius: 0 !important;
          }

          &:hover {
            outline: 1px solid $zg_color_border_default_onLight;
            background-color: $zg_color_bg_light;
          }

        }
      }
    }

    .link-section-one {
      li {
        flex-basis: 33%;
      }
    }

    .link-section-two {
      padding-left: 0 !important;
      margin-left: -16px;

      li {
        flex-basis: 100%;
        @include media-breakpoint-up(lg) {
          flex-basis: 50%;
        }
      }
    }

    .link-section-three {
      gap: 20px;

      li {
        width: fit-content;

        a {
          width: fit-content;

          .link-title {
            width: 100%;
            margin-bottom: 0;
            padding-right: 2.5rem;
          }
        }
      }
    }
  }

  #searchButtonNavDesktop.show::after {
    left: 12px;
    @include media-breakpoint-up(lg) {
      left: 22px;
    }
  }

  .search-menu-dropdown-desktop {
    height: calc(98vh - 80px);
    @include media-breakpoint-up(lg) {
      height: calc(98vh - 97px);

      .form-control::placeholder,
      .form-control {
        font-size: 1.625rem;
        line-height: 2.25rem;
      }

      .btn.search {
        padding: 24px 68px 24px 48px;

        &:after {
          right: 18px;
        }
      }
    }
  }


  // Mobile Nav
  #nav-mobile {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    &.is-visible {
      display: block;
    }

    .list-wrapper {
      height: 100%;
      padding: 30px 16px 50px 16px;

      @include media-breakpoint-up(md) {
        padding-bottom: 0;
      }
      overflow-y: auto;
      background-color: $zg_color_bg_white;
      overflow-x: hidden;
    }

    .list-wrapper:nth-child(2),
    .list-wrapper:nth-child(3),
    .list-wrapper:nth-child(4) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transform: translateX(100%);
      backface-visibility: hidden;
      transition: transform 0.5s;

      &.is-visible {
        transform: none;
      }
    }

    .list-wrapper:nth-child(1) > ul > li > .sub-menu,
    .list-wrapper:nth-child(2) .level-3 {
      display: none;
    }

    .mobile-nav-footer {
      position: relative;

      @include media-breakpoint-up(md) {
        bottom: -15px;
      }

      a {
        width: fit-content;
        color: $zg_color_text_onLight_high_contrast;
      }
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        border-top: 1px solid $zg_color_border_default_onLight;

        &:last-child {
          border-bottom: 1px solid $zg_color_border_default_onLight;
        }
      }

      &.suggestions-list {

        li {
          border-top: none;

          &:first-child {
            border-top: 1px solid $zg_color_separator_onLight;
          }
        }
      }

      &.level-1 {
        height: auto;
        margin-bottom: 0;
        padding-bottom: 80px;

        li {
          &::after {
            content: '';
            background-size: 1.3rem;
            background-repeat: no-repeat;
            background-position: right;
            display: inline-block;
            height: 1.3rem;
            width: 13%;
            position: relative;
            vertical-align: middle;
            background-image: get-icon('chevronRight', $zg_color_accent);
          }

          a {
            color: $zg_color_text_onLight_medium_contrast;
            font-weight: $zg_font-weight-bold;
            width: 85%;
          }
        }
      }

      &.level-2 {
        li a {
          color: $zg_color_text_onLight_high_contrast;
          font-weight: $zg_font-weight-bold;

          &::after {
            content: '';
            background-size: 1.3rem;
            background-repeat: no-repeat;
            background-position: right;
            display: inline-block;
            height: 1.3rem;
            width: 2rem;
            position: relative;
            vertical-align: middle;
            background-image: get-icon('chevronRight', $zg_color_accent);
          }
        }
      }

      &.level-3 {
        li a {
          .link-title {
            color: $zg_color_text_onLight_high_contrast;
            font-weight: $zg_font-weight-bold;

            &::after {
              content: '';
              background-size: 1.3rem;
              background-repeat: no-repeat;
              background-position: right;
              display: inline-block;
              height: 1.3rem;
              width: 2rem;
              position: relative;
              vertical-align: middle;
            }

            &.link-extern::after {
              background-image: get-icon('diagonal', $zg_color_accent);
            }

            &.link-intern::after {
              background-image: get-icon('chevronRight', $zg_color_accent);
            }
          }

          .link-keywords {
            color: $zg_color_text_onLight_medium_contrast;
          }
        }
      }
    }

    a {
      display: inline-block;
      position: relative;
      padding: 5px 0;
      text-decoration: none;
      width: 100%;
    }

    .back-one-level {
      display: flex;
      align-items: center;
      border: none;
      padding-left: 0;
      font-weight: $zg_font-weight-regular;
      text-transform: uppercase;

      &:hover, &:active {
        background-color: transparent;
        color: $zg_color_text_onLight_high_contrast;
      }

      &::before {
        content: '';
        background-size: 1.3rem;
        background-repeat: no-repeat;
        background-position: left;
        display: inline-block;
        height: 1.3rem;
        width: 2rem;
        position: relative;
        vertical-align: middle;
        background-image: get-icon('arrowLeft', $zg_color_text_onLight_high_contrast);
      }
    }

    .level-2.organisation-links {
      li a {
        &::after {
          all: unset;
        }

        .link-title {
          color: $zg_color_text_onLight_high_contrast;
          font-weight: $zg_font-weight-bold;
          margin-bottom: 0;

          &::after {
            content: '';
            background-size: 1.3rem;
            background-repeat: no-repeat;
            background-position: right;
            display: inline-block;
            height: 1.3rem;
            width: 2rem;
            position: relative;
            vertical-align: middle;
          }

          &.link-extern::after {
            background-image: get-icon('diagonal', $zg_color_accent);
          }

          &.link-intern::after {
            background-image: get-icon('chevronRight', $zg_color_accent);
          }
        }

        .link-keywords {
          color: $zg_color_text_onLight_medium_contrast;
          font-weight: $zg_font-weight-regular;
        }
      }

      .section-two {
        width: 100vw;
        position: relative;
        left: -32px;
        padding-left: 32px;
        padding-right: 16px;

        li {
          &:last-child {
            border-bottom: none;
          }

          &:first-of-type {
            border-top: none;
          }
        }
      }
    }

    #searchButtonNavMobile {
      border: none;
      color: $zg_color_text_onLight_high_contrast;
      position: relative;
      display: inline-block;
      background-color: transparent;
      padding-right: 0;
      padding-left: 30px;
      background: get-icon('search', $zg_color_text_onLight_medium_contrast);
      background-repeat: no-repeat;
      background-size: 1.2rem;
      background-position: left;
    }

    #searchDropdownMobile {
      top: 0;
      height: 100vh;
      border: none;

      .search-field-row {
        border-bottom: 1px solid $zg_color_separator_onLight;

        input[type="search"] {
          border: none;
          background: get-icon('search', $zg_color_text_onLight_medium_contrast);
          background-repeat: no-repeat;
          background-size: 1.2rem;
          padding-left: 30px;
          background-position: left;

          &:focus,
          &:focus-visible {
            box-shadow: none;
            outline: none !important;
            @include media-breakpoint-up(md) {
              outline: 2px solid $zg_color_focus !important;
            }
          }

          .btn-close-main-nav-search-overlay-mobile {
            background-size: 14px;
          }
        }
      }
    }

    .icon-header-menu.person {
      margin-left: 0;
      position: relative;
      top: 4px;
    }

    // dossier tabs Nav
    .card-container {
      .hero-home-dossier-tabs {
        padding-bottom: 30px;
      }
    }
  }
}
