footer {
  .img-logo {
    width: 100%;
    height: auto;
    max-width: 132px;
    @include media-breakpoint-up(md) {
      max-width: 150px;
    }
  }

  .newsletter-box h2,
  .newsletter-box p,
  nav {
    padding-right: 1rem;
    @include media-breakpoint-up(md) {
      padding-top: 2rem;
    }
    @include media-breakpoint-up(lg) {
      padding-right: 0;
    }
  }

  .newsletter-box {
    padding-top: 40px;
    @include media-breakpoint-up(md) {
      padding-top: 0;
    }
  }

  .footer-nav {
    nav {
      a {
        display: block;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &.level-one a {
        font-size: $zg_font-size-h2-small;
        color: $zg_color_text_onDark_high_contrast;
        padding-bottom: 0.75rem;
        font-weight: $zg_font-weight-bold;
        @include media-breakpoint-up(md) {
          font-size: $zg_font-size-h2-medium;
        }
        @include media-breakpoint-up(lg) {
          font-size: $zg_font-size-h2-large;
          padding-bottom: 2.125rem;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }

      &.level-two a {
        color: $zg_color_text_onDark_medium_contrast;
        font-size: $zg_font-size-body-small;
        padding-bottom: 0.9rem;
        @include media-breakpoint-up(md) {
          font-size: $zg_font-size-body-medium;
        }
        @include media-breakpoint-up(lg) {
          font-size: $zg_font-size-body-large;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  .footer-links-list-col {
    @media (max-width: 364px) {
      min-width: 170px;
      margin: auto;
    }
  }

  .footer-links-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      border-left: 1px solid $zg_color_separator_onLight;

      &:last-child {
        padding-right: 0 !important;
      }

      &:first-child {
        padding-left: 0 !important;
        border-left: none;
      }

      a {
        text-decoration: none;
        color: $zg_color_text_onLight_high_contrast;

        &:hover {
          text-decoration: underline;
        }

        &.icon-footer-login {
          display: inline-block;
          width: max-content;

          &:before {
            content: '';
            background-size: 1.5rem;
            background-repeat: no-repeat;
            background-position: center;
            display: inline-block;
            height: 1.5rem;
            width: 2.6rem;
            background-image: get-icon('person', $zg_color_text_onLight_high_contrast);
            position: relative;
            top: 5px;
          }
        }
      }

      .btn-icon,
      .btn-icon:hover {
        border: none;
        top: -2px;
        font-weight: $zg_font-weight-regular;
        margin: 0;
        text-align: left;
        padding: 0;
        background-color: transparent;
        color: $zg_color_text_onLight_high_contrast;
      }

      .btn-icon:after {
        width: 5rem;
      }
    }

  }

  .contact-links a {
    text-decoration: none;
    color: $zg_color_text_onDark_medium_contrast;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }
}