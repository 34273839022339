.qicklink-group {

  .remove-spacing {
    margin-bottom: -32px;
  }

  .quick-link-container {
    container-type: inline-size;

    .quick-link-button {
      text-overflow: ellipsis;
      overflow: hidden;
      height: 200px;
      position: relative;

      @include media-breakpoint-up(lg) {
        @container (min-inline-size: 250px) {
          aspect-ratio: 5/4;
          height: auto;
        }
      }

      .colored-background {
        border-radius: 20px;
        background: var(--zg_color_accent);
        position: absolute;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(100%);
        mix-blend-mode: darken;
      }

      .button-content,
      .colored-background {
        height: 100%;
        width: 100%;
      }

      .button-content {
        background: $zg_color_primary;
        position: absolute;
        top: 0;

        .icon {
          width: 32px;
          height: 32px;
          min-width: 32px; // to make flex col big enough
          min-height: 32px; // to make flex col big enough
          background-size: 32px;
          background-position: center;
          background-repeat: no-repeat;
          background-color: transparent;

          @include media-breakpoint-up(lg) {
            width: 40px;
            height: 40px;
            min-width: 40px; // to make flex col big enough
            background-size: 40px;
          }
        }

        @each $name, $icon in $icon-map {
          .#{$name} {
            &.icon {
              background-image: get-icon-custom-width($name, 2, $zg_color_text_onDark_high_contrast);
            }
          }
        }


        p, h3 {
          color: $zg_color_text_onDark_high_contrast;
          overflow: hidden;
          text-overflow: ellipsis;
          hyphens: auto;
        }

        h3 {
          @include media-breakpoint-up(lg) {
            line-height: 1.5rem;
            font-size: 1.25rem;
          }
          @media screen and (min-width: 1281px) {
            line-height: 2.25rem;
            font-size: 1.75rem;
          }
        }

        p {
          flex: 1;

          &.small {
            line-height: 16px;
            @include media-breakpoint-up(md) {
              line-height: 16.8px;
            }
            @include media-breakpoint-up(lg) {
              line-height: 16.8px;
              font-size: 0.75rem;
            }
            @media screen and (min-width: 1281px) {
              line-height: 20px;
              font-size: 1rem;
            }
          }
        }
      }
    }

    &:hover {
      .button-content {
        background: linear-gradient(to bottom, var(--zg_color_accent) 15%, var(--zg_color_accent_transparent));

        p, h3 {
          color: $zg_color_text_onLight_high_contrast;
        }

        @each $name, $icon in $icon-map {
          .#{$name} {
            &.icon {
              background-image: get-icon-custom-width($name, 2, $zg_color_text_onLight_high_contrast);
            }
          }
        }
      }
    }
  }
}