/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
  url('../../zug-theme/fonts/Inter-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../zug-theme/fonts/Inter-Regular.woff') format('woff'), /* Modern Browsers */
  url('../../zug-theme/fonts/Inter-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
  url('../../zug-theme/fonts/Inter-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../zug-theme/fonts/Inter-Bold.woff') format('woff'), /* Modern Browsers */
  url('../../zug-theme/fonts/Inter-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}
