.support-links {
  .support-links-container {
    /* prevent box-shadow of links beeing cut off on the left */
    padding-left: 32px;
    margin-left: -32px;
    padding-right: 32px;
    margin-right: 32px;

    a {
      color: $zg_color_text_onLight_medium_contrast;
      flex: 0 0 auto;
      text-decoration: none;
      border: 1px solid $zg_color_border_extraLight_onLight;
      border-radius: $zg_border-radius-middle;
      box-shadow: $zg_box-shadow-extraLargeSoft;
      padding: 1rem 1.5rem;
      margin-right: 1rem;
      line-height: 1.375rem;
      font-size: 1rem;
      @include media-breakpoint-up(md) {
        padding: 1.5rem 2rem;
        font-size: 0.938rem;
      }
      @include media-breakpoint-up(lg) {
        padding: 1.5rem 2rem;
        margin-right: 2rem;
        line-height: 1.625rem;
        font-size: 1.125rem;
      }

      &:focus-visible {
        box-shadow: $zg_box-shadow-large;
        border: none;
      }

      &:hover {
        border-color: $zg_color_border_hover_onLight;
        box-shadow: $zg_box-shadow-large;
      }
    }
  }

  h2 {
    margin-bottom: 0;
  }
}