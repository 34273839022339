.jobs-navigation-area {

  .jobs-navigation-wrapper {
    @include media-breakpoint-up(md) {
      border-bottom: 1px solid $zg_color_separator_onLight;
      overflow-x: scroll;
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        background: transparent; /* Chrome/Safari/Webkit */
        width: 0;
        height: 0;
      }
    }
  }

  p.title-mobile {
    color: $zg_color_text_onLight_low_contrast;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  nav {
    display: block;
    background-color: white;
    margin-right: 1rem;
    flex-wrap: nowrap;

    @include media-breakpoint-up(md) {
      display: inline-flex;
      white-space: nowrap;
      width: 100%;
      padding-bottom: 0;
      margin-right: 0;
    }
  }

  .jobs-navigation-wrapper.is-edit-mode nav {
    display: block;
  }

  a {
    text-decoration: none;
    color: $zg_color_text_onLight_low_contrast;
    border-bottom: 1px solid $zg_color_separator_onLight;
    padding-left: 0;
    padding-bottom: 16px;
    padding-top: 16px;
    display: block;

    &::before {
      content: attr(data-text);
      content: attr(data-text) / "";
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: $zg_font-weight-bold;
    }

    @include media-breakpoint-up(md) {
      font-size: $zg_font-size-body-medium;
      border-bottom: none;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @include media-breakpoint-up(md) {
      &.active {
        color: $zg_color_text_onLight_high_contrast;
        font-weight: $zg_font-weight-bold;
        border-bottom: 4px solid $zg_color_primary;
        padding-bottom: 12px;
        padding-left: 0.85rem;
        padding-right: 0.85rem;
      }

      &:hover {
        color: $zg_color_text_onLight_high_contrast;
      }
    }

    @include media-breakpoint-up(lg) {
      font-size: $zg_font-size-body-large;
    }
  }

  .isDragged nav a {
    pointer-events: none;
  }

}