$zg_newsFilterButtons_fontsize_large: 1.125rem;
$zg_newsFilterButtons_fontsize_medium: 0.938rem;
$zg_newsFilterButtons_fontsize_small: 0.875rem;

.news-list {

  .filterArea {

    .switcher-button {
      border: 1px solid $zg_color_border_default_onLight;
      border-radius: $zg_border-radius-middle;
      width: fit-content;
      //height: 100%;

      @include media-breakpoint-up(lg) {
        margin-left: 1rem;
      }

      .btn {
        border: none;
        margin: 0;
        border-radius: 0;
        color: $zg_color_text_onLight_medium_contrast;
        font-weight: $zg_font-weight-regular;
        padding: 1rem;
        font-size: $zg_font-size-label;
        @include media-breakpoint-up(lg) {
          font-size: 1.125rem;
        }

        &:first-of-type {
          border-right: 1px solid $zg_color_border_default_onLight;
        }

        &:nth-of-type(2) {
          margin-left: -5px;
        }

        &:hover {
          background-color: transparent;
        }

        &:after {
          display: block;
          content: attr(title);
          font-weight: $zg_font-weight-bold;
          height: 1px;
          color: transparent;
          overflow: hidden;
          visibility: hidden;
        }

        &.bold {
          font-weight: $zg_font-weight-bold;
        }
      }

      .btn-check:checked + .btn {
        font-weight: $zg_font-weight-bold;
      }

      .btn-check:focus + .btn {
        box-shadow: none;
      }
    }

    .dropdown {
      margin-left: 16px;
      flex-shrink: 1;
    }

    .dropdown-menu{
      width: 100%;
      max-height: 250px;
      overflow-y: auto;

      .dropdown-item{
        white-space: normal;
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        &.hidden {
          display: none;
        }
      }


    }

    .btn-direction-dropdown,
    .btn-office-dropdown {
      background-color: transparent;
      border: 1px solid $zg_color_border_default_onLight;
      margin-bottom: 0;
      color: $zg_color_text_onLight_medium_contrast;
      font-weight: $zg_font-weight-regular;
      padding: 1rem 2.75rem 1rem 1rem;
      font-size: $zg_newsFilterButtons_fontsize_small;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;

      @include media-breakpoint-up(md) {
        font-size: $zg_newsFilterButtons_fontsize_medium;
      }

      @include media-breakpoint-up(lg) {
        font-size: $zg_newsFilterButtons_fontsize_large;
      }


      &::after {
        background-image: get-icon('chevronDown', $zg_color_accent);
      }

      &.show:after {
        background-image: get-icon('chevronUp', $zg_color_accent);
      }

    }

    .input-group > .form-control {
      height: 60px;
    }

    #filter-button {
      font-weight: $zg_font-weight-regular;
    }

    #mobile-filters {
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      overflow: hidden;

      .switcher-button {
        width: auto;

        .btn {
          width: 100%;
          border: none;
          border-bottom: 1px solid $zg_color_border_default_onLight;
          border-radius: 0 !important;
          margin: 0;

          &:last-child {
            border-bottom: none;
          }
        }

      }

      .direction-select,
      .office-select {
        select {
          height: 3.75rem;
          .hidden {
            display: none;
          }
        }
      }
    }

    .remove-filter-button {
      padding: 0 0.75rem 0 2.75rem;
      font-weight: $zg_font-weight-regular;
      border: none;
      color: $zg_color_text_onLight_high_contrast;
      border-left: 1px solid $zg_color_separator_onLight;
      border-radius: 0;
      margin-left: 1.5rem;

      &::before {
        background: center / 1rem 1rem no-repeat;
        @include media-breakpoint-up(md) {
          background: center / 1.4rem 1.4rem no-repeat;
        }
        bottom: 0;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 3rem;
        line-height: 1.563rem;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        color: $zg_color_text_onLight_high_contrast;
      }

      &:hover:before,
      &:focus:before,
      &:active:before {
        background-image: get-icon(refresh, $zg_color_text_onLight_high_contrast);
      }

      &::before {
        background-image: get-icon(refresh, $zg_color_text_onLight_high_contrast);
      }
    }

    .search-field {
      height: 60px;
    }

    input[type=search] {
      background: get-icon(search, $zg_color_accent) right 1rem center/1.5rem no-repeat;
      @include media-breakpoint-up(md) {
        background: get-icon(search, $zg_color_accent) right 1rem center/1.5rem no-repeat;
      }
      bottom: 0;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      line-height: 1.563rem;
      width: 100%;
    }

    // center text on ios
    input[type="date"] {
      line-height: 60px;
    }
  }

  .newsArea {
    .news-item {
      text-decoration: none;
      width: 100%;
      display: block;

      .label {
        font-size: $zg_newsFilterButtons_fontsize_small;

        @include media-breakpoint-up(md) {
          font-size: $zg_newsFilterButtons_fontsize_medium;
        }

        @include media-breakpoint-up(lg) {
          font-size: $zg_newsFilterButtons_fontsize_large;
        }
      }

      img {
        max-height: 80px;
        max-width: 80px;
        margin-right: 0;
        margin-left: auto;
        border-radius: $zg_border-radius-middle;

        @include media-breakpoint-up(md) {
          max-height: 96px;
          max-width: 96px;
        }

        @include media-breakpoint-up(lg) {
          max-height: 186px;
          max-width: 186px;
        }
      }
    }
  }

  .more-area {
    a {
      &:focus,
      &:active {
        border: 1px solid $zg_color_border_default_onLight;

        &::after {
          background-image: get-icon('arrowRight', $zg_color_accent);
        }
      }

      &:focus-visible {
        outline: none !important;
        border: 3px solid $zg_color_focus;
      }

      &:hover::after {
        background-image: get-icon('arrowRight', $white);
      }
    }
  }

  &.zu-po {
    .switcher-button {
      @include media-breakpoint-up(lg) {
        border: 1px solid #ABB3CC;
      }

      .btn {
        @include media-breakpoint-up(lg) {
          border: none;
          border-right: 1px solid $zg_color_border_default_onLight;

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    .search-field input {
      @include media-breakpoint-up(md) {
        width: auto;
        right: 0;
      }
      @include media-breakpoint-up(lg) {
        left: 0;
        right: auto;
      }
    }
  }
}