.download-list-component {
  .download-wrapper-link {
    text-decoration: none;
    display: block;
    border-radius: 20px;

    &:focus-visible {
      .download-list-item {
        border-color: transparent;
      }
    }

    &:hover {
      .download-list-item {
        border: 1px solid $zg_color_border_hover_onLight;
      }
    }
  }

  .download-list-item {
    border: 1px solid $zg_color_border_default_onLight;

    > div:first-child {
      max-width: 90%;
    }

    p {
      color: $zg_color_text_onLight_high_contrast;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .asset-metadata {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      line-height: 1.2rem;
      color: $zg_color_text_onLight_medium_contrast;
      padding: 0 5px;
      border-right: 1px solid $zg_color_border_default_onDark;
      @include media-breakpoint-up(md) {
        padding: 0 8px;
      }

      &:last-child {
        padding-right: 0;
        border-right: none;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .icon {
    background-size: 1.5rem;
    background-repeat: no-repeat;
    background-position: center;
    height: 1.5rem;
    width: 1.5rem;
    align-self: center;
    background-image: get-icon('download', $zg_color_accent);
  }

  button:focus:not(:focus-visible) {
    border: 1px solid $zg_color_border_default_onLight;
  }
  button.showMore[aria-expanded="false"]{
    -webkit-animation-delay: 0.8s;
    opacity: 0;
    -webkit-animation: fading ease-in 0.5s;
    animation: fading ease-in 0.8s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }

  button.showMore[aria-expanded="true"]{
    display: none;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .rounded-2{
    @include media-breakpoint-up(md) {
      border-radius: 20px !important;
    }
  }

}

@keyframes fading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}