.navigation-component {
  nav {
    padding-left: 0 !important;

    @include media-breakpoint-up(md){
      padding-right: 2rem !important;
    }


    ol {
      list-style: none;
      padding-left: 1rem;
      margin-right: 0 !important;
      @include media-breakpoint-up(md){
        padding-left: 2rem;
      }

      .navigation-card {
        font-size: $zg_font-size-body-small;
        &:not(:last-child){
          border-bottom: 1px solid $zg_color_text_onLight_medium_contrast;
        }

        @include media-breakpoint-up(md) {
          font-size: $zg_font-size-body-medium;
          &:not(:last-child){
            border-bottom: none;
          }
        }

        @include media-breakpoint-up(lg) {
          font-size: $zg_font-size-body-large;
        }

        a {
          text-decoration: none;
          display: block;
          margin-left: -1rem;
          padding-left: 1rem;
        }

        .navigation-card-title {
          font-weight: $zg_font-weight-bold;
          position: relative;

          p {
            padding-right: 0.75rem;
            color: $zg-color_text_onDark_high_contrast;
            max-width: 92%;

            @include media-breakpoint-up(md) {
              padding-right: 1rem;
            }

            @include media-breakpoint-up(lg) {
              padding-right: 1.25rem;
            }
          }

          .icon {
            content: '';
            background-size: 1rem;
            background-repeat: no-repeat;
            background-position: center;
            height: 28px;
            width: 1rem;
            position: absolute;

            @include media-breakpoint-up(md) {
              background-size: 1.25rem;
              height: 28px;
              width: 1rem;
            }

            background-image: get-icon-custom-width('chevronRight', 8, $zg_color_accent);

          }
        }

        .navigation-keywords {
          color: $zg_color_text_onDark_medium_contrast;
          padding-right: 1rem;
          @include media-breakpoint-up(md) {
            padding-right: 0;
          }
        }
      }
    }
  }
}