.component-contact-box {
  .ml-0 {
    margin-left: 0 !important;
  }

  .btn-contact-form {
    &:focus,
    &:active {
      border: 1px solid $zg_color_border_default_onLight;
    }

    &:focus-visible {
      outline: none !important;
      border: 3px solid $zg_color_focus;
    }
  }

  .email a,
  .phone a {
    text-decoration: none;
    color: $zg_color_text_onLight_medium_contrast;
  }

  .box-header {
    p {
      font-size: 0.75rem;
      @include media-breakpoint-up(md) {
        font-size: 0.813rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
  }

  .box-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .contact-info {
      flex-basis: 100%;
      height: fit-content;
      @include media-breakpoint-up(md) {
        flex-basis: 35%;
        &.contact-info-image {
          flex-basis: 65%;
        }
      }
      @include media-breakpoint-up(lg) {
        &.contact-info-image {
          flex-basis: 75%;
        }
      }

      address {
        margin-bottom: 0;
      }

      .icon {
        background-size: 2.5rem;
        background-repeat: no-repeat;
        background-position: center;
        height: 2.5rem;
        width: 2.5rem;
        display: block;

        &.location {
          background-image: get-icon-custom-width('pin', 2, $zg_color_text_onLight_medium_contrast);
        }

        &.phone {
          background-image: get-icon-custom-width('phone-call', 2, $zg_color_text_onLight_medium_contrast);
        }

        &.email {
          background-image: get-icon-custom-width('mail', 2, $zg_color_text_onLight_medium_contrast);
        }

        &.time {
          background-image: get-icon-custom-width('time', 2, $zg_color_text_onLight_medium_contrast);
        }
      }

      .social-media-links {
        display: grid;
        grid-template-columns: repeat(4, min-content);
        justify-content: space-between;
        row-gap: 32px;
        @include media-breakpoint-up(md) {
          grid-template-columns: repeat(3, min-content);
        }
        @include media-breakpoint-up(lg) {
          grid-template-columns: repeat(6, min-content);
        }

        &.social-media-links-small {
          @include media-breakpoint-up(md) {
            grid-template-columns: repeat(2, min-content);
          }
          @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, min-content);
          }
        }
      }
    }
  }

  .map-or-image-wrapper {
    .map {
      position: relative;
      height: 290px;

      @include media-breakpoint-up(lg) {
        height: 530px;
      }
    }

    .contact-image {
      position: relative;
    }
  }

  .map-wrapper-mobile {
    .map {
      max-width: 100%;
      position: relative;
      top: -55px;
      height: 215px;
    }
  }
}