.icon-test {
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: center;
  height: 3rem;
  width: 3rem;
  display: inline-block;
  &.bin {
    background-image: get-icon('bin', $zg_color_accent);
  }
  &.arrow-up {
    background-image: get-icon('arrowUp', $zg_color_bg_dark);
  }
  &.download {
    background-image: get-icon('download', $zg_color_secondary);
  }
}

.highC {
  background-color: $zg_color_text_onLight_high_contrast;
}

.mediumC {
  background-color: $zg_color_text_onLight_medium_contrast;
}

.lowC {
  background-color: $zg_color_text_onLight_low_contrast;
}

.hyperlink {
  background-color: $zg_color_text_onLight_hyperlink;
}

.highCD {
  background-color: $zg_color_text_onDark_high_contrast;
}

.mediumCD {
  background-color: $zg_color_text_onDark_medium_contrast;
}

.lowCD {
  background-color: $zg_color_text_onDark_low_contrast;
}

.hyperlinkD {
  background-color: $zg_color_text_onDark_hyperlink;
}

.test-td {
  background-color: $zg_color_button_tertiary_dark;
}

.test-search {
  background-color: $zg_color_button_search;
}

.test-hover {
  background-color: $zg_color_button_hover;
}

.test-sep {
  background-color: $zg_color_separator_onLight;
}

.test-border {
  background-color: $zg_color_border_default_onLight;
}

.test-focus {
  background-color: $zg_color_focus;
}

.test-hover-l {
  background-color: $zg_color_border_hover_onLight;
}

.test-disabled {
  background-color: $zg_color_border_disabled_onLight;
}

.test-extra-light-l {
  background-color: $zg_color_border_extraLight_onLight;
}

.test-sep-d {
  background-color: $zg_color_separator_onDark;
}

.test-border-d {
  background-color: $zg_color_border_default_onDark;
}

.test-hover-d {
  background-color: $zg_color_border_hover_onDark;
}

.test-disabled-d {
  background-color: $zg_color_border_disabled_onDark;
}