.statements-list {

  .filter-row {

    > .col-12 {
      flex-direction: column-reverse;

      @include media-breakpoint-up(md) {
        flex-direction: row;
        gap: 16px;
      }
    }

    .radio-item {
      cursor: pointer;
      border: 1px solid $zg_color_border_default_onLight;
      margin-right: 16px;


      .radio-item-input {
        appearance: none;
        display: block;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
      }

      .radio-item-label {
        font-size: 1.125rem;
        cursor: pointer;

        &:after {
          color: transparent;
          content: attr(title);
          display: block;
          font-weight: $zg_font-weight-bold;
          height: 0px;
          overflow: hidden;
          visibility: hidden;
        }
      }

      .radio-item-input:checked + .radio-item-label span {
        font-weight: $zg_font-weight-bold;
      }

      &:hover {
        border-color: $zg_color-border_hover_onLight;
      }
    }

    .input-group {
      @include media-breakpoint-up(md) {
        width: auto;
      }

      input[type="search"] {
        font-size: 1rem;
        padding: 14px;

        background: get-icon(search, $zg_color_accent) right 1rem center/1.5rem no-repeat;
        @include media-breakpoint-up(md) {
          background: get-icon(search, $zg_color_accent) right 1rem center/1.5rem no-repeat;
        }

        &::placeholder {
          font-size: $zg_font-size-label;
        }
      }
    }

    .filter-mobile {
      width: 100%;
      padding-top: 16px;
      @include media-breakpoint-up(md) {
        width: auto;
        padding-top: 0;
      }

      .form-select {
        padding-right: 32px !important;
        font-weight: $zg_font-weight-bold;
        @include media-breakpoint-up(md) {
          width: auto;
        }

        option {
          font-weight: $zg_font-weight-regular;
        }
      }
    }


  }

  .tableHeaderArea {
    p {
      color: $zg_color_text_onLight_low_contrast;
      line-height: 1rem;
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
      @include media-breakpoint-up(md) {
        font-size: 0.813rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }

    hr:first-of-type {
      margin-top: 0;
    }
  }

  .statementsArea {
    .date {
      font-size: 0.75rem;
      line-height: 1rem;
      @include media-breakpoint-up(md) {
        font-size: 0.813rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }

    .title {
      font-size: 1.125rem;
      line-height: 1.5rem;
      @include media-breakpoint-up(md) {
        font-size: 1rem;
        line-height: 1.188rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

  }
}