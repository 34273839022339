// Set gutters on rows for all breakpoints
$grid-gutter-widths: (
        xs: 8px,
        md: 24px,
        lg: 32px
);

@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
  @include media-breakpoint-up($grid-breakpoint) {
    $grid-gutter-half-width: $grid-gutter-width / 2;
    .row:not(.no-gutters):not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5):not(.gx-6) {
      margin-right: -$grid-gutter-half-width;
      margin-left: -$grid-gutter-half-width;
    }
    .row:not(.no-gutters):not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5):not(.gx-6) > .col,
    .row:not(.no-gutters):not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5):not(.gx-6) > [class*="col-"] {
      padding-right: $grid-gutter-half-width;
      padding-left: $grid-gutter-half-width;
    }
  }
}

// Set space left and right of container
.container {
  width: calc(100vw - 32px);
  padding-left: 0;
  padding-right: 0;
  @include media-breakpoint-up(md) {
    width: calc(100vw - 64px);
  }
  @include media-breakpoint-up(lg) {
    width: calc(100vw - 160px);
  }
}

// Set space left and right of container-lg
.container-lg {
  padding-left: 0;
  padding-right: 0;
  @include media-breakpoint-up(lg) {
    width: calc(100vw - 160px);
  }
}

// Styles for wrapper-divs of containers with overflow background on left or right side
.gradient-bg-dark-from-left {
  background-color: $zg_color_bg_dark;
  background-image: linear-gradient(
                  to right,
                  $zg_color_bg_dark 0 50%,
                  $white 50% 100%
  );
}

.gradient-bg-dark-from-right {
  background-color: $zg_color_bg_dark;
  background-image: linear-gradient(
                  to left,
                  $zg_color_bg_dark 0 50%,
                  $white 50% 100%
  );
}

.gradient-bg-light-from-left {
  background-color: $zg_color_bg_light;
  background-image: linear-gradient(
                  to right,
                  $zg_color_bg_light 0 50%,
                  $white 50% 100%
  );
}

.gradient-bg-light-from-right {
  background-color: $zg_color_bg_light;
  background-image: linear-gradient(
                  to left,
                  $zg_color_bg_light 0 50%,
                  $white 50% 100%
  );
}

/* For rows inside of dark containers */
.row.no-ml {
  margin-left: 0 !important;
  
  @include media-breakpoint-up(lg) {
    margin-left: 16px !important;
  }
}