// Variables
$zg_border-radius-small: 5px;
$zg_border-radius-middle: 10px;
$zg_border-radius-large: 20px;

$zg_box-shadow-small: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
$zg_box-shadow-middle:  0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
$zg_box-shadow-large: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
$zg_box-shadow-extraLarge:  0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
$zg_box-shadow-extraLargeSoft: 0px 3px 20px -10px rgba(0, 0, 0, 0.06);

$btn-border-radius: $zg_border-radius-middle;
$btn-border-radius-sm: $zg_border-radius-middle;
$btn-border-radius-lg: $zg_border-radius-middle;
$btn-font-weight: $zg_font-weight-bold;
$btn-box-shadow: none;

$spacer: 1rem;
$spacers: (
        0: 0,
        4: $spacer * .25,
        8: $spacer * .5,
        16: $spacer * 1,
        24: $spacer * 1.5,
        32: $spacer * 2,
        40: $spacer * 2.5,
        48: $spacer * 3,
        56: $spacer * 3.5,
        64: $spacer * 4,
        80: $spacer * 5,
        104: $spacer * 6.5,
        128: $spacer * 8,
        152: $spacer * 9.5,
);

// override bootstrap
$utilities: (
        "rounded": (
                property: border-radius,
                class: rounded,
                values: (
                        null: $zg_border-radius-middle,
                        0: 0,
                        1: $zg_border-radius-small,
                        2: $zg_border-radius-middle,
                        3: $zg_border-radius-large,
                        circle: 50%,
                )
        ),
        "rounded-top": (
                property: border-top-left-radius border-top-right-radius,
                class: rounded-top,
                values: (
                        null: $zg_border-radius-middle,
                        0: 0,
                        1: $zg_border-radius-small,
                        2: $zg_border-radius-middle,
                        3: $zg_border-radius-large,
                )
        ),
        "rounded-end": (
                property: border-top-right-radius border-bottom-right-radius,
                class: rounded-end,
                values: (
                        null: $zg_border-radius-middle,
                        0: 0,
                        1: $zg_border-radius-small,
                        2: $zg_border-radius-middle,
                        3: $zg_border-radius-large,
                )
        ),
        "rounded-bottom": (
                property: border-bottom-right-radius border-bottom-left-radius,
                class: rounded-bottom,
                values: (
                        null: $zg_border-radius-middle,
                        0: 0,
                        1: $zg_border-radius-small,
                        2: $zg_border-radius-middle,
                        3: $zg_border-radius-large,
                )
        ),
        "rounded-start": (
                property: border-bottom-left-radius border-top-left-radius,
                class: rounded-start,
                values: (
                        null: $zg_border-radius-middle,
                        0: 0,
                        1: $zg_border-radius-small,
                        2: $zg_border-radius-middle,
                        3: $zg_border-radius-large,
                )
        ),
        "shadow": (
                property: box-shadow,
                class: shadow,
                values: (
                        null: $zg-box-shadow-middle,
                        sm: $zg-box-shadow-small,
                        md: $zg_box-shadow-middle,
                        lg: $zg_box-shadow-large,
                        xl: $zg_box-shadow-extraLarge,
                        xl-soft: $zg_box-shadow-extraLargeSoft,
                        none: none,
                )
        ),

);