.inPageNavigationArea {
  z-index: 10;
  //position: sticky;
  top: 0;
  scroll-behavior: smooth;
  min-width: 1px; min-height: 1px;
  pointer-events: none;

  @include media-breakpoint-up(md) {
    position: sticky;
    background-color: $zg_color_bg_white;
    box-shadow: 0px 1px 1px #D5D7E6;
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      background: transparent; /* Chrome/Safari/Webkit */
      width: 0;
      height: 0;
    }
  }

  p.title-mobile {
    padding-left: 1rem;
    color: $zg_color_text_onLight_medium_contrast;
    text-transform: uppercase;
  }

  #in-page-navigation {
    display: block;
    background-color: white;
    margin-right: 1rem;
    pointer-events: none;
    margin-left: 1rem;
    @include media-breakpoint-up(md) {
      transform: translateX(-25px);
      padding-top: 0;
      margin-left: 0;
    }

    &.minified-nav {
      background-color: transparent;
      padding-top: 0;
    }

    @include media-breakpoint-up(md) {
      display: inline-flex;
      width: 100%;
      padding-bottom: 0;
      margin-right: 0;
    }

    ul.nav {
      display: grid;
      list-style: none;
      flex-wrap: nowrap;
      padding-top: 0;

      @include media-breakpoint-up(md) {
        display: flex;
        padding-top: 1.5rem;
        white-space: nowrap;
      }

      @include media-breakpoint-up(md) {

        li:last-child {
          .nav-link {
            margin-right: 16px;
          }
        }
      }

      li:first-child {
        padding-top: 1rem;
        @include media-breakpoint-up(md) {
          padding-top: 0;
        }
      }

      &.minified-list {

        li {
          padding-top: 0;
          margin-left: -1rem;
          margin-right: -1rem;
          padding-left: 1rem;
          padding-right: 1rem;
          border-bottom: 1px solid $zg_color_separator_onLight;

          &:not(.active-li) {
            order: initial;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s linear 0s, opacity 500ms;
          }

          &.active-li {
            order: -1;
            visibility: visible;
            opacity: 1;
            transition: visibility 0s linear 0s, opacity 0s;
            background-color: $zg_color_bg_white;
            pointer-events: auto;
          }

          a.active {
            &::after {
              background: center / 24px 24px no-repeat;
              bottom: 0;
              content: '';
              position: absolute;
              right: 0;
              top: 0;
              width: 3rem;
              line-height: 1.563rem;
              background-image: get-icon('chevronDown', $zg_color_accent);
            }
          }
        }
      }

      &.expanded {
        li:not(.active-li) {
          visibility: visible;
          opacity: 1;
          height: unset;
          transition: visibility 0s linear 0s, opacity 500ms;
          background-color: $zg_color_bg_white;
          pointer-events: auto;
        }

        &.minified-list li a.active {
          &::after {
            background-image: get-icon('chevronUp', $zg_color_accent);
          }
        }
      }

      &:not(.minified-list) {
        li {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 0s, opacity 1s;
          pointer-events: auto;
          a.nav-link {
            border-bottom: 1px solid $zg_color_separator_onLight;
            @include media-breakpoint-up(md) {
              border-bottom: none;
            }
            &.active {
              padding-right: 0;
            }
          }
        }
      }

      a.nav-link {
        text-decoration: none;
        color: $zg_color_text_onLight_medium_contrast;
        //border-bottom: 1px solid $zg_color_separator_onLight;
        padding-left: 0;
        padding-bottom: 1rem;
        padding-top: 1rem;
        position: relative;
        line-height: 1.375rem;

        &.active {
          color: $zg_color_text_onLight_medium_contrast;
          padding-right: 2.5rem;
        }

        @include media-breakpoint-up(md) {
          padding-bottom: 11px;
          padding-top: 0;
          line-height: 1.5rem;

          &:hover{
            color: $zg_color_text_onLight_high_contrast;
          }

          &.active {
            color: $zg_color_text_onLight_high_contrast;
            font-weight: $zg_font-weight-bold;
            border-bottom: 4px solid $zg_color_primary !important;
            padding-bottom: 8px;
          }
        }

        @include media-breakpoint-up(md) {
          font-size: $zg_font-size-body-medium;
          padding-left: 0;
          padding-right: 0;
          margin-right: 2.5rem;
          border-bottom: none;
        }

        @include media-breakpoint-up(lg) {
          font-size: $zg_font-size-body-large;
          line-height: 1.75rem;
        }
      }

    }
  }
}

.inPageNavigationArea + section {
  padding-top: 2.5rem;
  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}