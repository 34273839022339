$grid-breakpoints: (
        xs: 0,
        md: 720px,
        lg: 1025px,
);

$container-max-widths: (
        xs: 688px,
        md: 961px,
        lg: 1280px
);

// use with class g-0 g-1 ... in row
$grid-gutter-width: 1rem;
$spacer: 1rem !default;
$gutters: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,    // 8px
        3: $spacer,         // 16px
        4: $spacer * 1.5,   // 24px
        5: $spacer * 2,     // 32px
        6: $spacer * 5,     // 80px
);