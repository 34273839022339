.testimonial-group {
    .relative {
        position: relative;
    }

    .quote-marks {
        top: 0px;
    }

    .one-item .quote-marks {
        top: 8px;
    }

    
    .testimonial-item {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        img {
            width: 80px;
            height: 80px;
            @include media-breakpoint-up(md){
                width: 97px;
                height: 97px;
            }
            @include media-breakpoint-up(lg){
                width: 109px;
                height: 109px;
            }
        }
        .quote {
            color: #000000;
        }
        .name,
        .function {
            color: $zg_color_primary;
        }
        .quote-marks {
            position: absolute;
            left: 0;
            z-index: -1;
        }

        .small {
            line-height: 1rem;
            @include media-breakpoint-up(md){
                line-height: 1.05rem;
            }
            @include media-breakpoint-up(lg){
                line-height: 1.25rem;
            }
        }
    }

    .one-item .testimonial-item img {
        width: 144px;
        height: 144px;   
    }
}

