.accordion-component {

  .pr-4 {
    padding-right: 4px;
  }

  .accordion {
    border-top: 1px solid $zg_color_separator_onLight;
    border-bottom: 1px solid $zg_color_separator_onLight;

    .accordion-button {
      padding: 16px 0;
      color: $zg_color_text_onLight_medium_contrast;
      font-size: $zg_font-size-body-small;
      line-height: 1.375rem;

      @include media-breakpoint-up(md) {
        font-size: $zg_font-size-body-medium;
        line-height: 1.5rem;
        padding: 24px 0;
      }
      @include media-breakpoint-up(lg) {
        font-size: $zg_font-size-body-large;
        line-height: 1.75rem;
      }

      &:not(.collapsed) {
        font-weight: $zg_font-weight-bold;
        box-shadow: none;
      }

      &.icon-wrapper::after {
        margin: 0 2px 0 auto;
        background-position: 50% 65%;
        background-image: get-icon(chevronDown, $zg_color_text_onLight_high_contrast);
      }
    }

    .accordion-body {
      padding-top: 0;
      padding: 1rem 1rem;
      @include media-breakpoint-up(lg) {
        padding: 1rem 2rem;
      }

    }

    table{
      width: 100% !important;

      tr{
        border-bottom: 1px solid $zg_color_separator_onLight;

        &:last-child{
          border-bottom: none;
        }

        td{
          padding-top: 1rem;
          padding-bottom: 1rem;

          &:nth-child(odd){
            padding-right: 16px;
          }
          &:nth-child(even){
            min-width: 80px;
          }
        }
      }
    }
  }
}

.microsite {
  .accordion-component .accordion .accordion-button {
    font-size: $zg_ms_font-size-body-small;
    line-height: $zg_ms_line_height_small;
    @include media-breakpoint-up(md) {
      font-size: $zg_ms_font-size-body-medium;
      line-height: $zg_ms_line_height_medium;
    }
    @include media-breakpoint-up(lg) {
      font-size: $zg_ms_font-size-body-large;
      line-height: $zg_ms_line_height_large;
    }
  }
}