// Search Button
.input-group.search-input {
  input[type='search']::-webkit-search-cancel-button {
    display: none;
  }

  input[type='search'] {
    z-index: 10;
  }

  button.btn-blue.search {
    border-radius: 10px !important;
    left: 0;
    margin-left: -16px !important;
    z-index: 10;
  }

  > .form-control:focus,
  > .form-control:focus-visible {
    outline: 2px solid $zg_color_focus !important;
    z-index: 1;
  }
}

// Magnolia Forms
.form-wrapper {
  fieldset {
    #mgnlhp {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 0;
      z-index: -1;
    }

    legend {
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: $zg_color_text_onLight_high_contrast;
      font-weight: $zg_font-weight-bold;
      @include media-breakpoint-up(lg) {
        font-size: 1.75rem;
        line-height: 2.25rem;
      }
    }

    .form-row {
      padding-top: 1rem;
      position: relative;

      .form-control {
        color: $zg_color_text_onLight_medium_contrast;
      }

      &.d-flex {
        gap: 0 2rem;
      }

      // fit all in one row
      fieldset.mod .form-item {
        margin-right: 16px;
        width: auto;
        display: inline-block;
      }

      // .form-item-m and .form-item-l ist for Text Inputs with 50% or 100% witdh
      .form-item-m:nth-last-child(2) {
        @include media-breakpoint-up(md) {
          padding-bottom: 0;
        }
      }

      .form-item-m,
      .form-item-l {
        position: relative;
        padding-bottom: 1rem;
        padding-top: 0!important;

        &:last-child {
          padding-bottom: 0;
        }

        input {
          &:not(:placeholder-shown),
          &:focus {
            & ~ .form-label {
              &:not(.no-label-down) {
                padding-top: 0.8rem !important;
                @include media-breakpoint-up(md) {
                  padding-top: 0.9rem !important;
                }
                @include media-breakpoint-up(lg) {
                  padding-top: 1rem !important;
                }
              }
            }
          }

          &:placeholder-shown {
            & ~ .form-label {
              &:not(.no-label-down) {
                padding-top: 1.3rem;
                @include media-breakpoint-up(md) {
                  padding-top: 1.7rem;
                }
              }
            }
          }
        }
      }

      .form-item-m {
        flex-basis: 100%;
        flex-grow: 1;
        max-width: 100%;
        @include media-breakpoint-up(md) {
          flex-basis: 45%;
          max-width: 49%;
        }
      }

      .form-item-l {
        flex-basis: 100%;
      }

      //noinspection ALL
      input[type='date'],
      input[type='month'] {
        padding: 1.7rem 1rem 1rem 1rem !important;
        color: $zg_color_text_onLight_medium_contrast;
        min-width: 95%;
        -webkit-appearance: auto;
        -moz-appearance: auto;
        min-height: 1.2em;
        @include media-breakpoint-up(md) {
          padding: 2rem 1rem 1rem 1rem !important;
        }
        @include media-breakpoint-up(lg) {
          line-height: 1.88rem;
        }
      }

      input[type='checkbox'],
      input[type='radio'] {
        -webkit-appearance: none;
        background-color: transparent;
      }

      input[type='text'],
      input[type='date'],
      input[type='month'],
      input[type='week'],
      input[type='email'],
      input[type='number'],
      input[type='password'],
      input[type='tel'] {
        height: 56px;
        @include media-breakpoint-up(md) {
          height: 64px;
        }
        @include media-breakpoint-up(lg) {
          height: 80px;
        }
      }

      .form-select {
        height: 56px;
        color: $zg_color_text_onLight_medium_contrast;

        &:not(:placeholder-shown) {
          padding: 1.3rem 1rem 1rem 1rem !important;
        }

        @include media-breakpoint-up(md) {
          height: 64px;
          &:not(:placeholder-shown) {
            padding: 1.7rem 1rem 1rem 1rem !important;
          }
        }
        @include media-breakpoint-up(lg) {
          height: 72px;
        }
      }

      input[type='text'],
      input[type='date'],
      input[type='month'],
      input[type='week'],
      input[type='email'],
      input[type='number'],
      input[type='password'],
      input[type='tel'],
      textarea,
      .form-select {
        padding: 1.6rem 1rem;
        font-size: $zg_font-size-label;
        line-height: $zg_line_height_medium;
        background-color: transparent;
        @include media-breakpoint-up(lg) {
          font-size: 1.125rem;
        }
        &:focus {
          padding: 2rem 1rem 1rem 1rem;
          @include media-breakpoint-up(md) {
            padding: 1.7rem 1rem 1rem 1rem;
          }
          @include media-breakpoint-up(lg) {
            padding: 2rem 1rem 1rem 1rem;
          }

          & ~ .form-label:not(.no-label-down) {
            padding-top: 1.7rem;
            color: $zg_color_text_onLight_low_contrast;
            font-size: $zg_font-size-label-small;
            @include media-breakpoint-up(md) {
              font-size: $zg_font-size-label-medium;
            }
            @include media-breakpoint-up(lg) {
              font-size: $zg_font-size-label;
            }
          }
        }

        &:not(:placeholder-shown) {
          padding: 2rem 1rem 1rem 1rem;
          @include media-breakpoint-up(md) {
            padding: 1.7rem 1rem 1rem 1rem;
          }
          @include media-breakpoint-up(lg) {
            padding: 2rem 1rem 1rem 1rem;
          }

          & ~ .form-label:not(.no-label-down) {
            padding-top: 1.7rem;
            color: $zg_color_text_onLight_low_contrast;
            font-size: $zg_font-size-label-small;
            @include media-breakpoint-up(md) {
              font-size: $zg_font-size-label-medium;
            }
            @include media-breakpoint-up(lg) {
              font-size: $zg_font-size-label;
            }
          }
        }

        &:focus,
        &:focus-visible {
          outline: 3px solid $zg_color_focus;
          box-shadow: none;
          border: none;
        }

        :invalid {
          border: 3px solid $zg_color_error;
        }

        .hidden {
          display: none;
        }

        #error {
          display: none;
          color: $zg_color_error;
        }

        &.error {
          color: $zg_color_error;
          border-color: $zg_color_error;

          ~ label {
            color: $zg_color_error !important;
          }
        }
      }

      .form-select {
        background-size: 1.5rem;
        line-height: 1.4rem;
        background-position: right 1.5rem center;
      }

      .error-text,
      .error-select,
      .checkbox-error {
        color: $zg_color_error;
        padding-top: 0.25rem;
        font-size: 0.6875rem;
        @include media-breakpoint-up(md) {
          font-size: 0.75rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: 0.875rem;
        }
      }

      .form-item-m input {
        & + .form-label:not(.no-label-down) {
          padding-top: 1.7rem;
        }

        &:focus {
          & + .form-label:not(.no-label-down) {
            padding-top: 1rem;
          }
        }

        &:not(:placeholder-shown) {
          & + .form-label:not(.no-label-down) {
            padding-top: 1rem;
          }
        }
      }

      .form-item-m:not(:nth-of-type(-n + 2)) {
        input {
          & + .form-label:not(.no-label-down) {
            //padding-top: 2.7rem;
          }

          &:focus {
            & + .form-label:not(.no-label-down) {
              padding-top: 1rem;
            }
          }

          &:not(:placeholder-shown) {
            & + .form-label:not(.no-label-down) {
              padding-top: 1rem;
            }
          }
        }
      }

      .form-item-l {
        input {
          & + .form-label:not(.no-label-down) {
            padding-top: 1.7rem;
          }

          &:focus {
            & + .form-label:not(.no-label-down) {
              padding-top: 1rem;
            }
          }

          &:not(:placeholder-shown) {
            & + .form-label:not(.no-label-down) {
              padding-top: 1rem;
            }
          }
        }
      }

      .form-label {
        color: $zg_color_text_onLight_medium_contrast;

        &.no-label-down {
          margin-bottom: 1.5rem;
        }
      }

      .form-label:not(.no-label-down) {
        position: absolute;
        padding-left: 1rem;
        padding-right: 1rem;
        top: 0;
        left: 0;
        padding-top: 2.2rem;
        @include media-breakpoint-up(md) {
          padding-top: 2.5rem;
        }
        @include media-breakpoint-up(lg) {
          padding-top: 2.8rem;
        }
      }

      .form-check {
        margin-bottom: 1.5rem;
        // Make inputs (box and circle) bigger
        font-size: 20px;
      }

      .form-check-input:checked[type='radio'] {
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23252342%27/%3e%3c/svg%3e');
      }

      .form-check-input:checked[type='checkbox'] {
        background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23252342%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e');
      }

      .form-check-input {
        border-color: $zg_color_border_default_onLight;
        border-width: 1px;

        + label {
          color: $zg_color_text_onLight_medium_contrast;
        }
      }

      .form-check-input:checked {
        background-color: $white;
        border-color: $zg_color_primary;
        border-width: 2px;

        + label {
          color: $zg_color_text_onLight_high_contrast;
        }
      }
    }

    .button-wrapper {
      padding-top: 1rem;
      display: flex;
      flex-flow: row wrap;

      input {
        flex-basis: 100%;
        margin-bottom: 0;
        text-align: left;

        &.btn-step-back,
        &.btn-submit {
          padding: 0.75rem;
          background-size: 1rem;
          line-height: 24px;
          @include media-breakpoint-up(md) {
            background-size: 1.5rem;
            padding: 1rem 0.85rem;
            line-height: 16px;
          }
          @include media-breakpoint-up(lg) {
            line-height: 26px;
            padding: 1rem;
          }
          background-repeat: no-repeat;
          background-position: 97% center;
        }

        &.btn-step-back {
          background-image: get-icon(arrowLeft, $zg_color_accent);
          flex-basis: auto;
          padding-right: 3.5rem;
          background-position: 90% center;
          margin-top: 1rem;
        }

        &.btn-submit {
          background-image: get-icon(arrowRight, $zg_color_accent);
        }
      }
    }
  }

  p.required ~ .required {
    display: none;
  }

  .form-row {
    &.has-option {
      .form-label:not(.no-label-down) {
        padding-top: 1.7rem;
        color: $zg_color_text_onLight_low_contrast;
        font-size: $zg_font-size-label-small;
        @include media-breakpoint-up(md) {
          font-size: $zg_font-size-label-medium;
        }
        @include media-breakpoint-up(lg) {
          font-size: $zg_font-size-label;
        }
      }
    }

    &.error {
      select {
        border-color: $zg_color_error;
      }

      label {
        color: $zg_color_error !important;
      }
    }
  }
}

// Date input for Android
// Normal Date Input in chrome on android does not show placeholder, there is only a little Dropdown arrow that indicates
// that the user can choose something. Bootstrap hides this arrow and makes the field appear like an empty text field
// We need to revert this behaviour so mobile android looks at least a bit like some kind of picker

input[type='date'] {
  appearance: auto !important;
  padding: 1rem !important;
  display: block;
  @include media-breakpoint-up(md) {
    appearance: none !important;
    padding: 0.375rem 0.75rem !important;
  }
}

// Fix mobile safari on ios
@supports (-webkit-touch-callout: none) {
  ::-webkit-date-and-time-value {
    text-align: left;
    line-height: 60px;
    padding-left: 16px;
  }
}

.microsite .form-wrapper fieldset .form-row .form-select option {
  background-color: $zg_color_bg_light;
}

.gradient-bg-light-from-right .form-container.container {
  margin-left: 0;
  @include media-breakpoint-up(lg) {
    margin-left: auto;
  }
}
