.paginationArea {

  .btn-icon-only {
    padding: 1.5rem;
    margin: 0 0.5rem;
    width: 40px;
    height: 40px;

    @include media-breakpoint-up(md) {
      width: 56px;
      height: 56px;
    }

    &.arrowLeft {
      background-image: get-icon(arrowLeft, $zg_color_accent);

      &:disabled {
        background-image: get-icon(arrowLeft, $zg_color_text_onLight_low_contrast);
      }

      &:hover {
        background-image: get-icon(arrowLeft, $zg_color_bg_light);
      }
    }

    &.arrowRight {
      margin-right: 1.5rem;
      background-image: get-icon(arrowRight, $zg_color_accent);

      &:disabled {
        background-image: get-icon(arrowRight, $zg_color_text_onLight_low_contrast);
      }

      &:hover {
        background-image: get-icon(arrowRight, $zg_color_bg_light);
      }
    }
  }
  
  p.btn {
    cursor: auto;
    top: 4px;
  }

  .page-number-button {
    border: none;
    font-weight: $zg_font-weight-regular;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    color: $zg_color_text_onLight_medium_contrast;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: $zg_color_text_onLight_medium_contrast;
    }

    &.active {
      font-weight: $zg-font-weight-bold;
    }
  }

  .pagination-number-separator {
    border: none;
    font-weight: $zg_font-weight-regular;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    color: $zg_color_text_onLight_medium_contrast;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: $zg_color_text_onLight_medium_contrast;
    }

    &.active {
      font-weight: $zg-font-weight-bold;
    }
  }
}