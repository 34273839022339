.section-table {
  .col-table {
    padding-left: 2px;

    &.matrix-table {
      th:first-child,
      td:first-child {
        text-transform: uppercase;
        color: $zg_color_text_onLight_high_contrast;
        box-shadow: inset 0 -1px 0 $zg_color_separator_onLight, inset -1px 0 0 $zg_color_accent, 9px 17px 15px $zg_color_separator_onLight;
        z-index: 1;
        background-color: $white;
        position: relative;
        @include media-breakpoint-up(md) {
          position: sticky;
          left: 0;
        }

        p {
          color: $zg_color_text_onLight_high_contrast;
        }
      }
      thead th:first-child:hover {
        background-color: $zg_color_text_onDark_high_contrast;
      }
    }

    &.color-header {
      thead {
        background-color: $zg_color_bg_light;
      }

      &.matrix-table {
        thead th:first-child {
          background-color: $zg_color_bg_light;

          &:hover {
            background-color: $zg_color_text_onDark_high_contrast;
          }
        }
      }
    }

    &.color-uneven {
      tr:nth-child(even) {
        td, th {
          background-color: $zg_color_bg_light;
          box-shadow: inset 0 -1px 0 $zg_color_separator_onLight;
        }
      }
    }

    &.color-uneven, &.color-uneven.matrix-table {
      thead, th:first-child {
        background-color: $zg_color_bg_light;
      }
      th:first-child:hover {
        background-color: $zg_color_text_onDark_high_contrast;
      }

      tr:nth-child(even) {
        td, th {
          background-color: $zg_color_bg_light;
          box-shadow: inset 0 -1px 0 $zg_color_separator_onLight;
        }
      }
    }

    &.color-uneven.matrix-table {
      tr:nth-child(even) {
        td:first-child {
          box-shadow: inset 0 -1px 0 $zg_color_separator_onLight, inset -1px 0 0 $zg_color_accent, 9px 17px 15px $zg_color_separator_onLight;
        }
      }
    }

    table {
      margin: auto;
      text-align: left;

      th {
        text-transform: uppercase;
        font-weight: $zg_font-weight-regular;
        color: $zg_color_text_onLight_high_contrast;
        vertical-align: middle;
        position: relative;
        padding: 16px 40px 16px 32px;
        box-shadow: inset 0 -1px 0 $zg_color_separator_onLight;
        cursor: pointer;
        font-size: 0.75rem;
        @include media-breakpoint-up(md) {
          font-size: 0.813rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: 1rem;
        }

        p {
          margin-bottom: 0;
          display: block;

          span {
            display: inline-block;
            color: $zg_color_text_onLight_high_contrast;
          }
        }

        p::after {
          content: "";
          height: 100%;
          width: 2.7rem;
          position: absolute;
          display: inline-block;
          background-size: 1rem 1rem;
          background-repeat: no-repeat;
          bottom: 0;
        }

        &.unsorted p::after {
          background-image: get-icon('arrowUp', $zg_color_separator_onLight), get-icon('arrowDown', $zg_color_separator_onLight);
          background-position: center, right;
        }

        &.asc {
          p::after {
            background-image: get-icon('arrowUp', $zg_color_text_onLight_high_contrast);
            background-position: center;
          }

          &:hover p::after {
            background-image: get-icon('arrowUp', $zg_color_text_onLight_high_contrast), get-icon('arrowDown', $zg_color_separator_onLight);
            background-position: center, right;
          }
        }

        &.desc {
          p::after {
            background-image: get-icon('arrowDown', $zg_color_text_onLight_high_contrast);
            background-position: right;
          }

          &:hover p::after {
            background-image: get-icon('arrowUp', $zg_color_separator_onLight), get-icon('arrowDown', $zg_color_text_onLight_high_contrast);
            background-position: center, right;
          }
        }

        @include media-breakpoint-up(md) {
          padding: 16px 60px 16px 32px;
          p::after {
            width: 3.5rem;
            background-size: 1.3rem 1.3rem;
          }

          &:hover {
            background-color: $zg_color_text_onDark_high_contrast;
          }
        }

        @include media-breakpoint-up(lg) {
          &.unsorted p::after {
            background-image: none;
          }

          &.unsorted:hover p::after {
            background-image: get-icon('arrowUp', $zg_color_separator_onLight), get-icon('arrowDown', $zg_color_separator_onLight);
            background-position: center, right;
          }
        }
      }

      tr {
        box-shadow: inset 0 -1px 0 $zg_color_separator_onLight, inset 0 -1px 0 $zg_color_separator_onLight;

        td {
          padding: 16px 32px;
          min-width: 250px;
        }
      }
    }
  }
}

.section-table-jobs.section-table {
  .col-table table tr td {
    min-width: 100px;
  }
}