.map {
  width: 100%;
  aspect-ratio: 3 / 2;
  z-index: 1;
  .leaflet-control-attribution {
    padding: 0.5rem 1rem 0.3rem 0.5rem;
  }

  .leaflet-pane {
    z-index: 1;
  }

  .leaflet-top, .leaflet-bottom {
    z-index: 2;
  }

  .leaflet-control-gps {
    padding: 2px;

    .gps-button {
      background-position: -1px -1px;
    }
  }

  .leaflet-div-icon {
    border: none;
    background-color: transparent;

    .map-marker {
      background-size: 2.5rem;
      background-repeat: no-repeat;
      background-position: center;
      height: 3.1rem;
      width: 3rem;
      display: block;
      position: relative;
      bottom: 39px;
      right: 17px;
      background-image: url('data:image/svg+xml;utf8,<svg width="49" height="62" viewBox="0 0 49 62" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.4821 24C46.4821 32.2334 40.9256 41.1269 34.9658 48.2126C32.033 51.6994 29.0946 54.6419 26.8874 56.7136C25.9374 57.6053 25.1253 58.3334 24.5106 58.8717C23.8959 58.3334 23.0838 57.6053 22.1338 56.7136C19.9266 54.6419 16.9882 51.6994 14.0554 48.2126C8.09558 41.1269 2.53906 32.2334 2.53906 24C2.53906 11.8475 12.3783 2 24.5106 2C36.6429 2 46.4821 11.8475 46.4821 24Z" fill="white" stroke="%237CA5D0" stroke-width="4"/><ellipse cx="24.5112" cy="24" rx="11.9858" ry="12" fill="%23435075"/></svg>');
    }
  }
}