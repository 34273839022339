.service-card-xl {

  &.gradient-bg-dark-from-right{
    background-image: none;

    @include media-breakpoint-up(md) {
      background-image: linear-gradient(
                      to left,
                      $zg_color_bg_dark 0 50%,
                      $white 50% 100%
      );
    }
  }

  .bg-dark {
      padding-left: 0 !important;
  }

  .download-wrapper-link {
    text-decoration: none;

    &:focus-visible .service-card-xl-file-download{
      outline: 3px solid $zg_color_focus;
    }

    &:last-child {
      .service-card-xl-file-download {
        margin-bottom: 0 !important;
      }
    }
  }

  .service-card-xl-link, .service-card-xl-file-download {
    border: 2px solid $zg_color_border_default_onDark;

    &:hover {
      border-color: $zg_color_border_hover_onDark;
    }

    .asset-metadata {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
        line-height: 1.2rem;
        color: $zg_color_text_onDark_medium_contrast;
        padding: 0 8px;
        border-right: 1px solid $zg_color_border_default_onDark;

        &:last-child {
          padding-right: 0;
          border-right: none;
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }

    .btn-icon.arrowRight:hover, .btn-icon.diagonal:hover{
      color: $zg_color_text_onDark_hyperlink;
      background-color: $zg_color_secondary;
    }
  }

  .service-card-xl-link {
    p.bold {
      color: $zg_color-text_onDark_high_contrast;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  .service-card-xl-file-download {
    p {
      color: $zg_color-text_onDark_high_contrast;
    }


    .icon {
      background-size: 1.5rem;
      background-repeat: no-repeat;
      background-position: center;
      height: 1.5rem;
      width: 1.5rem;
      align-self: center;
      background-image: get-icon('download', $zg_color_accent);
    }
  }
}