.microsite .search-page {
    form .select-wrapper.icon-wrapper {
        position: relative;
        &::after {
            top: 14px;
        }
    }

    @include media-breakpoint-down(md){
        .breadcrumb-navigation ol.breadcrumb li:first-child:before {
            content: ""!important;
            background-repeat: no-repeat;
            background-color: var(--zg_color_accent);
            display: inline-block;
            vertical-align: middle;
            background-image: get-icon-custom-width('chevronLeft', 9, $zg_color_text_onLight_high_contrast);
            border-radius: 50%;
            background-size: 10px;
            margin: auto;
            background-position: center;
            width: 12px;
            height: 12px;
            margin-right: 4px;
        }
    }
}