.link-list {

  .pr-2 {
    padding-right: 2px;
  }
  
  padding: 16px;

  a {
    text-decoration: none;

    &:last-child .list-link {
      border-bottom: 1px solid $zg_color_separator_onLight;
    }
    @include media-breakpoint-up(md) {
      &.twoCols:nth-child(odd) {
        padding-left: 2rem;
      }

      &.twoCols:nth-last-child(2) .list-link {
        border-bottom: 1px solid $zg_color_separator_onLight;
      }
    }

    &:focus-visible {
      outline: none !important;

      .list-link {
        border-color: transparent;
        outline: 3px solid $zg_color_focus;
      }
    }

    .list-link {
      border-top: 1px solid $zg_color_separator_onLight;

      &:hover {
        background-color: $zg_color_bg_light;
      }

    }

    .icon {
      content: '';
      background-size: 1rem;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      height: 1rem;
      width: 1rem;
      position: relative;
      float: right;

      @include media-breakpoint-up(md) {
        background-size: 1.5rem;
        height: 1.5rem;
        width: 1.5rem;
      }

      &.chevronRight {
        background-image: get-icon('chevronRight', $zg_color_accent);
      }

      &.diagonal {
        background-image: get-icon('diagonal', $zg_color_accent);
      }

      &.icon-wrapper {
        background-image: none;
        margin-right: 2px;
        &:after {
          margin: 0;
          width: 16px;
          height: 16px;
          background-size: 12px;
          @include media-breakpoint-up(md) {
            background-size: 18px;
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    p {
      max-width: 80%;
      color: $zg_color_text_onLight_high_contrast;
      font-size: $zg_font-size-body-small;

      @include media-breakpoint-up(md) {
        font-size: $zg-font-size-body-medium;

        @include media-breakpoint-up(lg) {
          font-size: $zg_font-size-lead-medium;
        }
      }
    }
  }
}

.microsite {
  .link-list a p {
    font-size: $zg_ms_font-size-body-small;
    line-height: $zg_ms_line_height_small;
    @include media-breakpoint-up(md) {
      font-size: $zg_ms_font-size-body-medium;
      line-height: $zg_ms_line_height_medium;
    }
  }
}