.search-page {

  input[type="search"] {
    height: 56px;

    @include media-breakpoint-up(md) {
      height: 64px;
    }

    @include media-breakpoint-up(lg) {
      height: 80px;
    }
  }

  .filter-row {
    .btn.btn-secondary {
      font-weight: $zg_font-weight-regular;
      padding: 1rem;
      margin-right: 0.5rem;

      &.active {
        font-weight: $zg_font-weight-bold;
        border-color: $zg_color_border_default_onLight;
      }

      &:hover {
        border: 1px solid $zg_color_border_hover_onLight;
        background-color: transparent;
        color: $zg_color_text_onLight_medium_contrast;
        font-weight: $zg_font-weight-bold;
      }

      &::before {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }

    .form-select{
      color: $zg_color_text_onLight_medium_contrast;
      font-weight: $zg_font-weight-bold;
      font-size: 0.875rem;
    }
  }

  .search-result-item {
    border-bottom: 1px solid $zg_color_separator_onLight;

    &.first-item {
      border-top: 1px solid $zg_color_separator_onLight;
    }

    &.bg-light {
      background-color: $zg_color_bg_light;
    }

    a {
      text-decoration: none;
    }

    .result-type p {
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      font-size: $zg_font-size-label;
      color: $zg_color_text_onLight_medium_contrast;
    }
    
    .excerpt-fragment {
      font-size: $zg_font-size-body-small;
      color: $zg_color_text_onLight_medium_contrast;

      @include media-breakpoint-up(md) {
        font-size: $zg_font-size-body-medium;
      }

      @include media-breakpoint-up(md) {
        font-size: $zg_font-size-body-large;
      }
    }
  }

  .paginationArea  p.btn {
    top: 0;
  }
}