.heroArea {

  .mr-48 {
    margin-right: 48px;
  }
  
  img[data-sizes="auto"].hero-image {
    width: calc(100% + 16px);
    height: auto;
    @include media-breakpoint-up(md) {
      width: calc(100% + 32px);
    }
    @include media-breakpoint-up(lg) {
      width: calc(100% + 80px);
    }
    @media only screen and (min-width: 1603px) {
      border-bottom-right-radius: 20px !important;
      border-top-right-radius: 20px !important;
    }
  }

  .bg-light {
    .lead,
    h1 {
      /*Breadcrumb muss später das gleiche padding haben*/
      padding-right: 20px;
    }
  }

  .breadcrumb-navigation,
  .container .row .col:last-child {
    opacity: 0;
    animation: 1s cubic-bezier(0.12, -0.01, 0.24, 0.99) 0.2s 1 fadeInFromInvisible forwards;
  }

  h1 {
    animation: 0.6s cubic-bezier(0.12, -0.01, 0.24, 0.99) 0.2s 1 fadeInHeroHeading;
    transform-origin: bottom left;
    animation-fill-mode: forwards;
    opacity: 0;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}

.heroArea.heroAreaHome {
  .hero-image {
    img[data-sizes="auto"].hero-home-image {
      max-width: 100%;
      height: auto;
      aspect-ratio: 1/1;
    }
  }

  ul.small-slider-nav {
    width: fit-content;
    margin: auto;
    border-bottom: none;

    button.nav-link {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      border: none;
      padding: 0;
      background: linear-gradient(to left, $zg_color_border_default_onLight 50%, $zg_color_border_hover_onLight 50%) right;
      background-size: 200%;

      &.active {
        width: 32px;
        // Background position duration needs to be set to the autoplay delay of the slider
        transition: width 0.5s, background-position 5s linear;
        border-radius: 6px;
        background-position: left;
      }
    }

    .nav-item {
      &:first-child {
        padding-right: 10px;
      }

      &:last-child {
        padding-left: 10px;
      }
    }
  }

  ul.big-slider-nav {
    border-bottom: none;

    button.nav-link {
      border: none;
      border-radius: 0;
      text-align: left;
      padding-left: 0;
      padding-top: 0;
      color: $zg_color_text_onLight_medium_contrast;

      &:hover, &:active {
        background-color: transparent;
        color: $zg_color_text_onLight_high_contrast;
        border: none;
      }

      &:before{
        content: '';
        position: relative;
        display: inline-block;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        margin-bottom: 8px;
        background: linear-gradient(to left, $zg_color_border_default_onLight 50%, $zg_color_text_onLight_high_contrast 50%) right;
        background-size: 200%;
      }

      &[aria-selected="true"] {
        color: $zg_color_text_onLight_high_contrast;

        &:before {
          animation: bg-pan-left 4.8s linear both;
        }
      }
    }
  }

  .search-input {
    animation: 1s linear 0.2s 1 fadeInFromInvisible;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  .form-control::placeholder,
  .form-control {
    font-size: 1rem;
    line-height: 1rem;
    @include media-breakpoint-up(md) {
      line-height: 1.05rem;
    }
    @include media-breakpoint-up(lg) {
      line-height: 1.625rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .btn.search {
      padding: 18px 50px 18px 16px;
    }
  }

  #hero-home-dossier-tabs-homePage {
    animation: 1s linear 0.2s 1 fadeInFromInvisible;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  .hero-home-dossier-tabs {
    .tab-pane {
      &.fade {
        transition: none;
      }

      .hero-image {
        overflow: clip;
      }

      .dossier-text {
        opacity: 0;
      }

      &.active {
        .dossier-text {
          animation: slide-in-bottom 1.0s cubic-bezier(0.12, -0.01, 0.24, 0.99) forwards, fade-out 4.0s 1.0s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
        }

        .hero-image img {
          animation: slide-in-right 1.0s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards, fade-out 4.0s 1.0s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards;
        }
      }
    }
  }
}

.homePage .navigation-component,
.lead,
.hero-image {
  opacity: 0;
  animation: 1s cubic-bezier(0.12, -0.01, 0.24, 0.99) 0.2s 1 fadeInFromInvisible forwards;
}

@keyframes fade-out {
  0%, 100% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInFromInvisible {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  55% {
    opacity: 100%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes fadeInHeroHeading {
  0% {
    opacity: 0;
    transform: rotate(3deg) translateY(24px);
  }
  50% {
    opacity: 100%;
  }
  100% {
    transform: rotate(0) translateY(0);
    opacity: 100%;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(32px);
    transform: translateY(32px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: left;
  }
}
