.dossier-slider {
  caret-color: transparent;
  .slider-nav-buttons {
    &.slider-nav-buttons-light {
      .btn-icon-only {
        background-color: transparent;
      }
    }

    .btn-icon-only {
      height: 3.2rem;
      width: 3.2rem;
      border-color: $zg_color_text_onLight_low_contrast;
      margin-bottom: 0;
      margin-top: 3px;

      @each $name, $theme in $zug-theme-colors {
        &.#{$name} {
          &.dossier-swiper-button-next {
            background-image: get-icon("arrowRight", map-get($theme, 'accent'));
          }

          &.dossier-swiper-button-prev {
            background-image: get-icon("arrowLeft", map-get($theme, 'accent'));
          }
        }
      }

      &:focus {
        border: 1px solid  $zg_color_text_onLight_low_contrast;
      }

      &.btn-disabled {
        background-position: center;
        background-size: 1.5rem;
        background-repeat: no-repeat;
        border: 1px solid $zg_color_border_disabled_onLight;
        &.dossier-swiper-button-next {
          background-image: get-icon("arrowRight", $zg_color_text_onLight_low_contrast);
        }

        &.dossier-swiper-button-prev {
          background-image: get-icon("arrowLeft", $zg_color_text_onLight_low_contrast);
        }
      }

      &:hover, &:active {
        border: 1px solid $zg_color_button_hover;
        background-color: $zg_color_button_hover;
        &.dossier-swiper-button-next {
          background-image: get-icon("arrowRight", $white);
        }

        &.dossier-swiper-button-prev {
          background-image: get-icon("arrowLeft", $white);
        }
      }
    }
  }

  .swiper-wrapper {
    transition-timing-function: cubic-bezier(0.57, 0, 0.25, 1);
  }

  .swiper-slide {
    height: auto;
  }

  .dossier-card-remove-top-border-color {
    border-top-color: transparent !important;
  }

  .dossier-card-link {
    text-decoration: none;
    display: inline-block;
    width: 100%;
    height: 100%;

    &:focus-visible {
      outline: transparent !important;
      .dossier-slide {
        outline: 3px solid $zg_color_focus;
        border-radius: 20px;
      }
    }

    .dossier-slide {
      height: 100%;

      .image-wrapper{
        //noinspection CssInvalidPropertyValue
        overflow: clip;

        img[data-sizes="auto"] {
          display: block;
          width: 100%;
          height: 100%;
          transition: transform 0.3s cubic-bezier(0.57, 0, 0.25, 1);
        }
      }

      .dossier-text {
        flex-grow: 1;
        p {
          text-decoration: none;
          margin-top: 1rem;
          transition: transform 0.3s cubic-bezier(0.57, 0, 0.25, 1);
          @include media-breakpoint-up(lg) {
            margin-top: 1.5rem;
          }
        }

        h3 {
          margin-bottom: 0;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          hyphens: none;
          word-break: break-word;
          transition: transform 0.3s linear;
        }
      }

      &:hover {
        box-shadow: $zg_box-shadow-large;

        .image-wrapper {
          border: 1px solid $zg_color_border_hover_onLight;
          border-bottom: none;
          img[data-sizes="auto"] {
            transform: translateY(-16px) scale(1.04);
          }
        }

        .dossier-text {
          border: 1px solid $zg_color_border_hover_onLight !important;
          border-top: none !important;

          p, h3 {
            transform: translateY(-8px);
          }
        }
      }
    }
  }

  .dossier-swiper {
    .dossier-swiper-pagination {
      width: fit-content;
      margin: auto;
      @include media-breakpoint-up(md) {
        display: none;
      }
      .swiper-pagination-bullet {
        opacity: 1;
        background: linear-gradient(to left, $zg_color_border_default_onLight 50%, $zg_color_primary 50%) right;
        background-size: 200%;
        &.dossier-swiper-pagination-bullet-active {
          opacity: 1;
          width: 32px;
          transition: width 0.5s, background-position 3s linear; // Background position time needs to be set to the autoplay delay of the slider
          border-radius: 6px;
          background-position: left;
        }
      }
    }

  }
}