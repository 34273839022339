// Zug Variables
@import "variables/variables";

// Bootstrap
@import "../../../../../node_modules/bootstrap/scss/bootstrap";

// Leaflet
@import "../../../../../node_modules/leaflet/dist/leaflet.css";
@import "../../../../../node_modules/leaflet-gps/dist/leaflet-gps.min.css";

// Swiper
@import "../../../../../node_modules/swiper/swiper";
@import "../../../../../node_modules/swiper/modules/a11y/a11y";
@import "../../../../../node_modules/swiper/modules/pagination/pagination";
@import "../../../../../node_modules/swiper/modules/navigation/navigation";
@import "../../../../../node_modules/swiper/modules/effect-fade/effect-fade";

// basic styles
@import "basic/index";

// Areas
@import "areas/index";

// Components
@import "components/index";

// microsite custom styles
@import "microsites/index";
