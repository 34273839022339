.gallery-slider-component {

  .btn-icon-only.search {
    height: 2.5rem;
    width: 2.5rem;
    background-color: $white;
    border: none;

    &:hover {
      background-color: $zg_color_secondary;

    }

    &:active:not(:hover),
    &:focus:not(:hover) {
      background-image: get-icon("search", $zg_color_text_onLight_high_contrast);
    }
  }

  .row.single-image {
    .btn-icon-only.search {
      position: relative;
      margin-top: -87px;
      left: 16px;
    }
  }

  .gallery-swiper-pagination {
    width: fit-content;
    margin: auto;
    padding-top: 1.8rem;
    position: relative;
    z-index: 5;

    .swiper-pagination-bullet {
      background-color: $zg_color_border_default_onLight;
      opacity: 1;

      &.gallery-swiper-pagination-bullet-active {
        background-color: $zg_color_primary;
        opacity: 1;
      }
    }
  }

  .gallery-slider {
    .gallery-col {
      @include media-breakpoint-up(md) {
        clip-path: inset(-100vw -100vw -100vw 0);
      }
    }

    .gallery-slider-nav-buttons {
      .btn-icon-only {
        height: 3.2rem;
        width: 3.2rem;

        &:focus {
          border: 1px solid $zg_color_border_default_onLight;
        }

        &.btn-disabled {
          background-position: center;
          background-size: 1.5rem;
          background-repeat: no-repeat;
        }
      }

      .gallery-fraction-pagination {
        width: 3.2rem;
        font-size: 0.75rem;

        @include media-breakpoint-up(lg) {
          font-size: 0.875rem;
        }

      }
    }

    .swiper-wrapper {
      transition-timing-function: cubic-bezier(0.57, 0, 0.25, 1);
    }

    .swiper-slide {
      width: auto;

      .gallery-slide {
        width: fit-content;

        img[data-sizes="auto"] {
          display: block;
          width: auto;
          min-height: 161px;
          @include media-breakpoint-up(md) {
            min-height: 293px;
          }
          @include media-breakpoint-up(lg) {
            min-height: 600px;
          }
        }

        img + div {
          width: 100px;
        }

        .btn-icon-only.search {
          position: absolute;
          margin-top: -54px;
          left: 16px;
        }
      }
    }
  }

  .image-text,
  .image-text-mobile,
  .image-text p,
  .image-text-mobile p,
  .image-text-mobile button,
  .image-text button {
    font-size: $zg_font-size-small-small;
    line-height: 16px;

    @include media-breakpoint-up(md) {
      font-size: $zg_font-size-small-medium;
      line-height: 16.8px;
    }
    @include media-breakpoint-up(lg) {
      font-size: $zg_font-size-small-large;
      line-height: 20px;
    }
  }

  .image-text-mobile {
    height: 50px;

    p {
      display: inline;
    }

    .btn-read-more,
    .btn-read-less {
      background-color: transparent;
      border: none;
      color: $zg_color_text_onLight_hyperlink;
      padding: 0;
      margin: 0;
    }

    .moreText {
      visibility: hidden;
      display: inline-block;
      height: 0;
    }
  }

  .galleryModal {

    .btn.gallery-navigation {
      width: 2.5rem;
      height: 2.5rem;
      background-size: 1rem;

      @include media-breakpoint-up(md) {
        width: 3.5rem;
        height: 3.5rem;
        background-size: 1.5rem;
      }

      @include media-breakpoint-up(lg) {
        width: 3rem;
        height: 3rem;
        background-size: 1.25rem;
      }

      &:focus {
        border: 1px solid $zg_color_border_default_onLight;
      }
    }

    .gallery-fraction-pagination {
      width: fit-content;
    }
  }

  .modal {
    .modal-dialog-scrollable .modal-content {
      overflow: auto;
      background-color: $zg_color_bg_light;
    }

    .gallery-fraction-pagination {
      letter-spacing: -2px;
      font-size: 1rem;
      min-width: 24px;
      text-align: center;
    }

    .gallery-modal-image-slider,
    .gallery-modal-text-slider {
      opacity: 0;
    }
    
    img {
      max-width: min(100%, 2600px);
      height: auto;
      max-height: min(93vh, 2600px);
    }

    .btn-close {
      width: 39px;
      height: 39px;
      background-size: 48px;
      opacity: 0.6;
      background-image: get-icon-custom-width('cross', 2.5,  $zg_color_text_onLight_high_contrast);

      &:hover {
        opacity: 1;
        background-image: get-icon-custom-width('cross', 2.5,  $zg_color_text_onLight_high_contrast);
      }
    }
  }
}