.bg-dark, .bg-primary {
  color: $zg_color_text_onDark_medium_contrast;

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6,
  .title {
    color: $zg_color_text_onDark_high_contrast;
  }

  p, .p {
    color: $zg_color_text_onDark_medium_contrast;
  }

  a {
    color: $zg_color_text_onDark_hyperlink;
  }

  hr {
    border-color: $zg_color_separator_onDark;
  }

  input.form-control  {
    border: 1px solid $zg_color_border_default_onDark;
  }

  .spinner-border {
    border-color: $zg_color_text_onDark_high_contrast;
    border-right-color: transparent;
  }
}