.microsite:not(.error404Page) .heroArea {
    .pr-16 {
        padding-right: 1rem!important;
    }
    .container {
        @include media-breakpoint-up(md) {
            width: 100%;
            max-width: 1024px;
        }
        @include media-breakpoint-up(lg) {
            width: calc(100vw - 160px);
            max-width: 1280px;
        }
    }

    p.lead, .p.lead {
        font-size: 1rem;
    }

    @media (min-width: 720px){
        p.lead, .p.lead {
            font-size: 1.25rem;
        }
    }

    @media (min-width: 1025px) {
        p.lead, .p.lead {
            font-size: 1.625rem;
        }
    }
    .hero-content {
        position: relative;
        margin-left: -16px;
        @include media-breakpoint-up(md) {
            margin-left: 0;
        }
    }

    .hero-card {
        background-color: $zg_color_primary;
        border-radius: 0 0 20px 0;

        h1, p {
            color: #E0EEF6;
        }
        @include media-breakpoint-up(md) {
            border-radius: 0px 40px 40px 0;
            width: 50%;
            position: absolute;
            left: 0;
            top: 120px;
        }

        @include media-breakpoint-up(lg) {
            min-width: 500px;
        }

        &.text-down {
            @include media-breakpoint-up(md) {
                position: relative;
                width: 75.5%;
                top: -258px;
                margin-bottom: -258px;
                min-height: 306px;
            }

            @include media-breakpoint-up(lg) {
                top: -218px;
                margin-bottom: -218px;
                min-height: 160px;
            }
        }
    }

    img[data-sizes=auto] {
        display: block;
        width: 100%;
        border-radius: 0 20px 0 0;
        @include media-breakpoint-up(md) {
            border-radius: 40px;
            height: 798px;
            object-fit: cover;
            object-position: center;

        }
    }

    .breadcrumb-navigation ol.breadcrumb:not(.on-white) li {
        position: relative;
        color: #E0EEF6;
        a {
            color: #E0EEF6;
        }
    }

    .breadcrumb-navigation ol.breadcrumb li:before  {
        content: ""!important;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;
        background-image: get-icon-custom-width('chevronLeft', 9, $zg_color_text_onLight_high_contrast);
        border-radius: 50%;
        background-size: 10px;
        margin: auto;
        background-position: center;
        width: 12px;
        height: 12px;
        margin-right: 4px;
        @include media-breakpoint-up(md) {
            background-image: get-icon('chevronRight', $zg_color_text_onDark_medium_contrast);
            background-color: transparent;
            margin: auto 4px auto 0;
            background-position: right;
            background-size: 12px;
        }
    }

    .breadcrumb-navigation ol.breadcrumb li:first-child:before {
        @include media-breakpoint-up(md) {
            display: none;
            background-image: none;
            background-color: transparent;
        }
    }
}