.process-and-checklist {
  h2 {
    padding-right: 15px;
    margin-bottom: 0;
  }

  h3, h4 {
    margin-bottom: 8px;
  }

  h4 {
    font-size: 1.125rem;
    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
  }

  p,
  ul, ol {
    line-height: 1.375rem;
    @include media-breakpoint-up(md) {
      line-height: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
      line-height: 1.75rem;
    }
  }

  ul, ol {
    padding-left: 0;

    li {
      border-top: 1px solid $zg_color_separator_onLight;

      &:last-child {
        padding-bottom: 0 !important;
      }

      .list-content {
        padding-left: 10%;
        padding-right: 15px;
        @include media-breakpoint-up(md) {
          padding-left: 16.6%;
          padding-right: 16.6%;
          min-height: 41px;
        }
        @include media-breakpoint-up(lg) {
          padding-left: 25.6%;
          padding-right: 25.6%;
        }

        // reset list styles for lists from rich text editor (in list-content)
        ul, ol {
          padding-left: 2rem;

          li {
            border: none;
            background: none;
          }
        }

        ul li {
          list-style: disc outside none;
          margin-bottom: 0;
        }

        ol li {
          list-style: decimal outside none;

          ol li{
            list-style: lower-latin outside none;
          }
        }
      }
    }
  }

  // Checklist
  .col-12 > ul {
    > li {
      list-style-type: none;
      margin-bottom: 1rem;
      background: get-icon-custom-width("check", 7, $zg_color_accent) no-repeat;
      background-position: left 32px;
      background-size: 1.5rem;

      @include media-breakpoint-up(md) {
        background-position: left 48px;
        background-size: 3rem;
      }
      @include media-breakpoint-up(lg) {
        background-position: left 56px;
      }
    }
  }

  // Process List
  .col-12 > ol {
    counter-reset: number;
    list-style-type: none;
    list-style-position: inside;

    > li {
      counter-increment: number;
      line-height: 1.8rem;
      @include media-breakpoint-up(lg) {
        line-height: 2.8rem;
      }


      &::marker {
        color: $zg_color_accent;
        font-weight: $zg_font-weight-bold;
        font-size: 1.125rem;
        content: counter(number, decimal-leading-zero) " ";
        counter-increment: number;
        @include media-breakpoint-up(md) {
          font-size: 2.25rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: 3.5rem;
        }
      }

      .list-content {
        position: relative;
        top: -1.6rem;
        margin-bottom: -1.6rem;
        @include media-breakpoint-up(md) {
          top: -2.4rem;
          margin-bottom: -2.4rem;
        }
        @include media-breakpoint-up(lg) {
          top: -3.7rem;
          margin-bottom: -3.7rem;
        }
      }
    }
  }
}